import React, { useState } from "react";
import { Button, Popover, Radio } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const NER = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [person, setPerson] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [date, setDate] = useState(false);
  const [norp, setNorp] = useState(false);

  const hide = () => {
    setIsVisible(false);
    setPerson(false);
    setOrganization(false);
    setDate(false);
    setNorp(false);
  };

  const show = () => {
    setIsVisible(true);
    setTimeout(() => {
      setPerson(true);
    }, 200);
    setTimeout(() => {
      setOrganization(true);
    }, 800);
    setTimeout(() => {
      setDate(true);
    }, 1200);
    setTimeout(() => {
      setNorp(true);
    }, 1600);
  };

  return (
    <div className="product-sub-tab">
      <h6>
        "Please label any people, places, or organizations in the following
        text."
      </h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.*createNamedentityrecognitionTask*({
`}
            <code>{`callback_url`}</code>:
            {`'http://www.example.com/callback',
`}
            <code>{`instruction`}</code>:
            {`
  'Please label any people, 
  places, or organizations in the following text.',
`}
            <code>{`params`}</code>:
            {` {
`}
            <code>{`    text`}</code>:
            {` 
        "When Sebastian Rharun started at 
        Google in 2007, few peoplw outside
        of the company took him seriously. 
        "I can tell you very senior CEOs of
        major American car companies would 
        shake my hand and turn away because I
        wasn't worth talking to," said Thrun.,
        now the co-founder and CEO of online
        higher education startup Udacity, in
        and interview with Recode earlier
        this week.",
`}
            <code>{`    labels`}</code>:
            {` 
      [
        {
`}
            <code>{`          name`}</code>:{" "}
            {`'H_PERS',
`}
            <code>{`          display_name`}</code>:{" "}
            {`'Person',
        },
        {
`}
            <code>{`          name`}</code>:{" "}
            {`'H_ORG',
          display_name: 'Organization',
        },
        {
`}
            <code>{`            name`}</code>:{" "}
            {`'H_LOC',
`}
            <code>{`            display_name`}</code>:{" "}
            {`'Location',
        },
        {
`}
            <code>{`            name`}</code>:{" "}
            {`'H_Date',
`}
            <code>{`            display_name`}</code>:{" "}
            {`'Date',
        },
        {
`}
            <code>{`            name`}</code>:{" "}
            {`'H_NORP',
`}
            <code>{`            display_name`}</code>:{" "}
            {`'NOPR',
        }
      ]
    },
}, (err, task) => {
      // do something with task
});  
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          <h6 style={{ textAlign: "left", lineHeight: "2.5" }}>
            "When&nbsp;
            <span className={person ? "txtperson txtbf" : ""}>
              Sebastian Rharun
            </span>
            &nbsp; started at{" "}
            <span className={organization ? "txtbf txtorganization" : ""}>
              Google
            </span>{" "}
            in&nbsp;
            <span className={date ? "txtbf txttime" : ""}>2007</span>, few
            people outside of the company took him seriously. " I can tell you
            very senior CEOs of major{" "}
            <span className={norp ? "txtbf txtnorp" : ""}>American</span> car
            companies would shake my hand and turn away because I wasn't worth
            talking to,"
            <br />
            said&nbsp;
            <span className={person ? "txtperson txtbf" : ""}>Thrun</span>, now
            the co-founder and CEO of online higher education startup&nbsp;
            <span className={organization ? "txtbf txtorganization" : ""}>
              Udacity
            </span>
            , in and interview with{" "}
            <span className={organization ? "txtbf txtorganization" : ""}>
              Recode
            </span>
            &nbsp; <br />
            <span className={date ? "txtbf txttime" : ""}>
              earlier this week
            </span>
            ."
          </h6>
        </div>
      </div>
    </div>
  );
};

export default NER;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "response": {
    "annotations": [
     {
        "label": "H_PERS",
        "text": "Sebastian Rharun",
        "start": 23,
        "end": 100
      },
       {
        "label": "H_DATE",
        "text": "2007",
        "start": 148,
        "end": 170
      },
       {
        "label": "H_NORP",
        "text": "American",
        "start": 138,
        "end": 150
      },
      {
        "label": "H_ORG",
        "text": "Google",
        "start": 42,
        "end": 57
      },
      {
        "label": "H_ORG",
        "text": "Recode",
        "start": 104,
        "end": 109
      },
     
    ]
  },
  "task_id": "94984fdsnd36",
  "task": { ... }
}
`}
        </pre>
      </div>
    </div>
  );
};
