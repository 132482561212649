import { React, useEffect } from "react";
import { Collapse } from "antd";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactGA from "react-ga";

const { Panel } = Collapse;

const Career = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Career";

    ReactGA.pageview(window.location);
  }, []);

  return (
    <div className="page">
      <section id="career-banner" className="container-fluid"></section>
      <section id="career-content" className="container-fluid">
        <div className="container">
          <h1>Work Culture</h1>
          <p>We are passionate about technology</p>
          <p>
            At Heu employees are allowed to work from home if their job duties
            permit it. People who are obliged to come in direct physical contact
            with customers are not eligible to telecommute, employees who carry
            out most of their work on a computer can work from home but will
            have to be available during working hours.
          </p>
          <hr style={{ margin: "35px 0px" }} />
          <h1>Current Openings</h1>
          <p>Be a part of our growing Heu family</p>
          <Collapse accordion className="career-accordion">
            <Panel header="Business Development Executive" key="1">
              <div className="career-panel">
                <h6>Responsibilities : </h6>
                <ul>
                  <li>
                    Handle lead generation and reach customers over emails and
                    calls
                  </li>
                  <li>
                    Convert inbound leads and briefing the benefits and the
                    process
                  </li>
                  <li>
                    Work on quotation generation and share it with the customers
                  </li>
                  <li>Handle customer onboarding and order processing</li>
                </ul>
                <h6>Qualifications:</h6>
                <ul>
                  <li>MBA in Marketing</li>
                  <li>Bachelor’s degree in Computer Science or IT</li>
                  <li>Excellent written and oral communication skills</li>
                </ul>
                <ExtraInfo />
              </div>
            </Panel>
            <Panel header="Frontend React JS Developer" key="2">
              <div className="career-panel">
                <h6>Responsibilities : </h6>
                <ul>
                  <li>Develop react app as per design wireframe</li>
                  <li>Create HTML/CSS styling for the web platform</li>
                  <li>Consume Rest API for handling various functionalities</li>
                  <li>Handle customer onboarding and order processing</li>
                </ul>
                <h6>Qualifications:</h6>
                <ul>
                  <li>
                    Master’s or bachelor’s degree in Computer Science or IT
                    Engineering or equivalent.
                  </li>
                  <li>Excellent written and oral communication skills</li>
                </ul>
                <ExtraInfo />
              </div>
            </Panel>
            <Panel header="Django Developer" key="3">
              <div className="career-panel">
                <h6>Responsibilities : </h6>
                <ul>
                  <li>Develop Rest API as per requirements document</li>
                  <li>
                    Work with a team of developers to ensure smooth delivery
                  </li>
                  <li>Handle database design and queries for applications</li>
                </ul>
                <h6>Qualifications:</h6>
                <ul>
                  <li>
                    Master’s or bachelor’s degree in Computer Science or IT
                    Engineering or equivalent.
                  </li>
                  <li>Excellent written and oral communication skills</li>
                </ul>
                <ExtraInfo />
              </div>
            </Panel>
            <Panel header="Full Stack Developer (Django with React JS)" key="4">
              <div className="career-panel">
                <h6>Responsibilities : </h6>
                <ul>
                  <li>
                    Develop backend functionalities along with API using Django
                  </li>
                  <li>
                    Understand client requirements and develop UI as per the
                    design using React JS
                  </li>
                  <li>Build scalable scheme for databases</li>
                </ul>
                <h6>Qualifications:</h6>
                <ul>
                  <li>
                    Master’s or bachelor’s degree in Computer Science or IT
                    Engineering or equivalent.
                  </li>
                  <li>Excellent written and oral communication skills</li>
                </ul>
                <ExtraInfo />
              </div>
            </Panel>
            <Panel header="Software Tester" key="5">
              <div className="career-panel">
                <h6>Responsibilities : </h6>
                <ul>
                  <li>Handle manual functional testing</li>
                  <li>Automate test scripts using third party tools</li>
                  <li>Maintain test cases and product understanding</li>
                </ul>
                <h6>Qualifications:</h6>
                <ul>
                  <li>
                    Master’s or bachelor’s degree in Computer Science or IT
                    Engineering or equivalent.
                  </li>
                  <li>Excellent written and oral communication skills</li>
                </ul>
                <ExtraInfo />
              </div>
            </Panel>
            <Panel header="React Native Developer" key="6">
              <div className="career-panel">
                <h6>Responsibilities : </h6>
                <ul>
                  <li>
                    Designing and building advanced applications for the Android
                    platform
                  </li>
                  <li>
                    Collaborating with cross-functional teams to define, design,
                    and ship new features
                  </li>
                  <li>
                    Unit-testing code for robustness, including edge cases,
                    usability, and general reliability
                  </li>
                </ul>
                <h6>Qualifications:</h6>
                <ul>
                  <li>
                    Master’s or bachelor’s degree in Computer Science or IT
                    Engineering or equivalent.
                  </li>
                  <li>Excellent written and oral communication skills</li>
                </ul>
                <ExtraInfo />
              </div>
            </Panel>
            <Panel header="IOS Developer" key="7">
              <div className="career-panel">
                <h6>Responsibilities : </h6>
                <ul>
                  <li>
                    Designing and building advanced applications for the iOS
                    platform
                  </li>
                  <li>
                    Collaborating with cross-functional teams to define, design,
                    and ship new features
                  </li>
                  <li>
                    Unit-testing code for robustness, including edge cases,
                    usability, and general reliability
                  </li>
                </ul>
                <h6>Qualifications:</h6>
                <ul>
                  <li>
                    Master’s or bachelor’s degree in Computer Science or IT
                    Engineering or equivalent.
                  </li>
                  <li>Excellent written and oral communication skills</li>
                </ul>
                <ExtraInfo />
              </div>
            </Panel>
          </Collapse>
        </div>
      </section>
    </div>
  );
};

export default Career;

const ExtraInfo = () => {
  return (
    <div className="career-extra-info">
      <h6>
        Experience : <span style={{ fontWeight: "400" }}>0 - 2 years</span>
      </h6>
      <p style={{ textAlign: "left", fontSize: "15px" }}>
        If interested, send your resume to <Link>careers@heu.ai</Link>
      </p>
    </div>
  );
};
