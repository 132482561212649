import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FacebookIcon from "../assets/images/facebook.png";
import LinkedInIcon from "../assets/images/linkedin.png";
import InstagramIcon from "../assets/images/instagram.png";
import ContactForm from "./ContactForm";
import ReactGA from "react-ga";

const Contact = (props) => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 50,
    });
    document.title="Contact Us";
    window.scrollTo(0, 0);

    ReactGA.pageview(window.location);
  }, []);

  return (
    <div className="page" style={{padding: "0px", marginTop: "60px"}}>
      <section id="contact-banner" className="container-fluid">
        <div className="container-xl">
          <h1>Contact Us</h1>
          <div className="row m-0 p-0">
            <div className="col-md-6 contact-box">
              <div className="contact-card">
                <PhoneOutlined
                  style={{ color: "#ffc107" }}
                  className="contact-card-icon"
                  rotate={90}
                />
                <h5>Talk To Our Team</h5>
                <ul className="contact-card-list">
                  <li>
                    <a href="tel:+918089544056">+91 808 954 4056</a>
                  </li>
                  <li>
                    <a href="tel:+917517487177">+91 751 748 7177</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 contact-box">
              <div className="contact-card">
                <MailOutlined
                  className="contact-card-icon"
                  style={{ color: "#28a745" }}
                />
                <h5>Email To Us</h5>
                <ul className="contact-card-list">
                  <li>
                    <a href={"mailto:director@heu.ai"}>director@heu.ai</a>
                  </li>
                  <li>
                    <a href={"mailto:sale@heu.ai"}>sale@heu.ai</a>
                  </li>
                  <li>
                    <a href={"mailto:info@heu.ai"}>info@heu.ai</a>
                  </li>
                  <li>
                    <a href={"mailto:support@heu.ai"}>support@heu.ai</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="social-media-list">
            <p>Follow us on : </p>
            <ul className="social-media-icons">
              <li>
                <Link to="/facebook" target="_blank">
                  <img src={FacebookIcon} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/linkedin" target="_blank">
                  <img src={LinkedInIcon} alt="" />
                </Link>
              </li>
              <li>
                <Link to="/instagram" target="_blank">
                  <img src={InstagramIcon} alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <ContactForm />
    </div>
  );
};

export default Contact;
