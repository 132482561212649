import React, { useEffect } from "react";
import "./Training.css";
import { Tag, Collapse } from "antd";
import {
  ClockCircleOutlined,
  FileDoneOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TrainingForm from "./TrainingForm";
import LearnFromUs from "./LearnFromUs";

import ReactGA from "react-ga";

const { Panel } = Collapse;

const tagStyle = {
  color: "black",
  padding: "6px 8px",
  borderRadius: "6px",
  fontSize: "14px",
  margin: "6px",
};

const NLP = () => {

  useEffect(() => {
    document.title = "Training";

    ReactGA.pageview(window.location);
  }, []);


  return (
    <div className="page-home">
      <section className="training-banner container-fluid" id="nlp-banner">
        <div className="container-xl row m-auto">
          <div className="col-md-6 training-info">
            <h1>Course 5 : Natural Language Processing</h1>
            <p>
              Natural Language Processing with Python course will take you
              through the essentials of text processing all the way up to
              classifying texts using Machine Learning algorithms. You will
              learn various concepts such as Tokenization, Stemming,
              Lemmatization, POS tagging, Named Entity Recognition, Syntax Tree
              Parsing and so on using Python’s most famous NLTK package. Once
              you delve into NLP, you will learn to build your own text
              classifier using the Naïve Bayes algorithm.
            </p>
            <div className="training-tags">
              <Tag
                style={tagStyle}
                icon={
                  <ClockCircleOutlined
                    style={{ color: "#f8b63a", fontSize: "22px" }}
                  />
                }
              >
                20 Hours
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <ProjectOutlined
                    style={{ color: "green", fontSize: "22px" }}
                  />
                }
              >
                25 Exercises
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <FileDoneOutlined
                    style={{ color: "blue", fontSize: "22px" }}
                  />
                }
              >
                4 Assignments
              </Tag>
            </div>
          </div>
          <div className="training-form-div col-md-6">
            <TrainingForm />
          </div>
        </div>
      </section>
      <section className="course-content container-fluid">
        <div className="container-xl m-auto">
          <h5>Course Curriculum</h5>
          <Collapse
            style={{ backgroundColor: "#f7f7f7" }}
            expandIcon={() => {
              return null;
            }}
          >
            <Panel header="Module 1 : Working with Text" key="1"></Panel>

            <Panel
              header="Module 2 : Natural Language Processing Basics"
              key="2"
            >
              <ul>
                <li>Introduction to NLP</li>
                <li>Spacy Setup</li>
                <li>Spacy Basics</li>
                <li>Tokenization</li>
                <li>Stemming</li>
                <li>Lemmatization</li>
                <li>Stop Words</li>
                <li>Phrase Matching and Vocabulary</li>
                <li>NLP Basic Assessment</li>
              </ul>
            </Panel>

            <Panel
              header="Module 3 : Part of Speech Tagging and Named Entity Recognition"
              key="3"
            >
              <ul>
                <li>Introduction to POS and NER</li>
                <li>Part of Speech tagging</li>
                <li>Visualization Part of Speech</li>
                <li>Named Entity Recognition</li>
                <li>Sentence Segmentation</li>
                <li>Part of Speech Assessment</li>
              </ul>
            </Panel>

            <Panel header="Module 4 : Text Classification" key="4">
              <ul>
                <li>SKLearn</li>
                <li>Text Feature Extraction</li>
                <li>Text Classification Assessment</li>
              </ul>
            </Panel>

            <Panel header="Module 5 : Semantics and Sentiment Analysis" key="5">
              <ul>
                <li>Semantics and Word Vectors using Spacy</li>
                <li>Sentiment analysis using NLTK</li>
                <li>Sentiment Analysis Project</li>
              </ul>
            </Panel>

            <Panel header="Module 6 : Topic Modelling" key="6">
              <ul>
                <li>Latent Dirichlet Allocation</li>
                <li>Non-negative Matrix factorization</li>
                <li>Topic Modelling Project</li>
              </ul>
            </Panel>

            <Panel header="Module 7 : NLP with Deep Learning" key="7">
              <ul>
                <li>Recurrent Neural Networks</li>
                <li>LSTM, GRU and Text Generation</li>
                <li>Creating Chat Bots</li>
              </ul>
            </Panel>
          </Collapse>
        </div>
      </section>
      <LearnFromUs />
    </div>
  );
};

export default NLP;
