import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import TranscriptionBefore from "../../../assets/images/img-transcription.jpg";
import TranscriptionAfter from "../../../assets/images/img-trans-run.gif";

const Transcription = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Select the most appropriate categories for the image shown."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createTextCollectionTask({
`}
            <code>{`callback_url:`}</code>
            {`
   'https://www.example.com/callback',
`}
            <code>{`instruction:`}</code>
            {`
   'Please transcribe the menu',
`}
            <code>{`params:`}</code>
            {`  {`}
            <code>{`
    attachments:`}</code>
      {`[
          {
              `}<code>{`type: `}</code>{` 'image'
              `}<code>{`content: `}</code>{` 'food-menu.jpg'
          }
      ],`}
            <code>{`
    fields:`}</code>
      {`[
          {
              `}<code>{`field_id: `}</code>{` 'item_name'
              `}<code>{`title: `}</code>{` 'Item Name'
              `}<code>{`required: `}</code>{` true
              `}<code>{`type: `}</code>{` 'text'
          },
          {
            `}<code>{`field_id: `}</code>{` 'item_price'
            `}<code>{`title: `}</code>{` 'Price'
            `}<code>{`required: `}</code>{` true
            `}<code>{`type: `}</code>{` 'number'
            `}<code>{`digits: `}</code>{` 3
        },
      ],
},(err, task) => {
  // do something with task
});
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={TranscriptionAfter} alt="" />
          ) : (
            <img src={TranscriptionBefore} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Transcription;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "response": {
    "annotations": {
      "item_name": {
        "type": "text",
        "response": ["tea"]
      },
      "item_price": {
        "type": "number",
        "response": [4.65]
      }
    }
  },
  "task_id": "34653dfdf4twwb25",
  "task": { ... }
}            
`}
        </pre>
      </div>
    </div>
  );
};
