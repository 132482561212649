import React, {useEffect} from "react";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title="Heu - Privacy Policy";
  }, []);

  return (
    <div className="page">
      <section id="privacy-policy" className="container-fluid">
        <div className="container-xl m-auto">
          <h1>
            <span style={{ fontWeight: "400" }}>Privacy</span> Policy
          </h1>
          <p>
            I consent to having HEU Technologies collect and process my personal
            details according to its Privacy Policy.
          </p>
          <p>
            This form collects your personal details so that we can contact you
            back to raise opportunities for cooperation, and we need your
            consent on that. You can withdraw your consent at any time by
            writing to us at info@heu.ai. Read our Privacy Policy to learn how
            we protect and manage your data.
          </p>
          <h6>Privacy Policy</h6>
          <p>
            HEU TECHNOLOGIES PRIVATE LIMITED, a Technology company, together
            with its affiliates and subsidiaries (collectively “HEU
            Technologies” or “we”), has prepared this Privacy Policy (“Policy”)
            to outline its online data protection practices. This Policy
            explains why and what information about you will be collected by HEU
            Technologies when you visit its website at www.heu.ai, and when you
            contact HEU Technologies by sending an email, filing in the contact
            form or otherwise, or when you apply for a job posted on our
            Website.
          </p>
          <p>
            This policy is an integral part of the Terms of Use of this Website.
            We might update this Policy in the future, so please make sure to
            read it from time to time. In case of any questions or comments as
            for this Policy, please reach out to us at info@heu.ai
          </p>
          <h6>Data Collection</h6>
          <p>
            We only collect information about you that is necessary for us to
            allow you to use the Website or contact us, and only to the extent
            specifically provided in this Policy.
          </p>
          <p>
            When you contact us via email or via contact form, you provide us
            your name, phone number, e-mail address, communication content and
            data associated with the communication. From time to time, HEU
            Technologies also receives personal information about individuals
            from third parties around the world. This may happen if you publish
            your CV/Resume on the Internet on job boards, giving them the
            consent to use and transmit your data, if someone recommended you
            for potential employment and in other similar cases. Please review
            the privacy policies of any third-party websites before providing
            your information to them.
          </p>
          <p>
            When you visit our Website, we may collect the following information
            about you:
          </p>
          <ul className="privacy-page-list">
            <li>
              Information about your device(s), in particular, hardware model,
              version of the operating system.
            </li>
            <li>
              Information about your visits to and use of this Website,
              including your IP address, geographical location, browser type and
              version, referral source, page views and Website navigation paths.
            </li>
            <li>Cookies.</li>
          </ul>
          <h6>Data Processing</h6>
          <p>
            We will only process your personal data where we have a legal basis
            to do so. The legal basis will depend on the reasons we have
            collected and need to use your personal data for. We may process
            your personal data because:
          </p>
          <ul className="privacy-page-list">
            <li>You have consented to us using your personal data.</li>
            <li>We need to comply with a legal obligation.</li>
            <li>It is in our legitimate business interests.</li>
          </ul>
          <p>
            More specifically, personal data submitted to us through this
            website and personal data we collected from you or third parties or
            from public sources will be used for the purposes based on the legal
            grounds as follows:
          </p>
          <ul className="privacy-page-list">
            <li>
              Communications, based on HEU Technologies legitimate interest,
              art. 6 para. 1 f GDPR :
              <ul className="privacy-inner-list">
                <li>
                  if you have contacted us via this website or otherwise, we may
                  process your personal data to be able to answer your
                  questions, to organize the meeting, call, zoom or other
                  communication. Please note that this communication may be
                  recorded and stored by HEU Technologies representative in HEU
                  Technologies corporate system.
                </li>
              </ul>
            </li>
            <li>
              Marketing and public relations, based on your consent, art. 6
              para. 1 a GDPR :
              <ul className="privacy-inner-list">
                <li>
                  ​​​If you are a visitor of our website, we may, by way of
                  cookies, process your data to carry out marketing research and
                  to analyse the characteristics of this website visitors, to
                  assess the impact of our marketing communication and to adjust
                  it to the detected tendencies, to plan our future marketing
                  activities, to prepare analytics and profiling for business
                  intelligence, to target our advertising.
                </li>
                <li>
                  If you are our subscriber, you receive or would like to
                  receive our marketing materials, we may process the personal
                  data you provided to us to spread promotional materials, to
                  make announcements about additions and changes concerning our
                  services, to send you e-mail notifications, to send you our
                  email newsletters.
                </li>
              </ul>
            </li>
            <li>
              Recruitment based on your consent, art. 6 para. 1 a GDPR :
              <ul className="privacy-inner-list">
                <li>
                  If you are a potential employee, we may process your personal
                  data to assist you with ongoing or future employment or
                  cooperation with HEU Technologies, to enable you to submit
                  your resume, apply for jobs online or express your interest in
                  relocation.
                </li>
                <li>
                  Based on HEU Technologies legitimate interest, art. 6 para. 1
                  f GDPR: In specific cases, and only upon the client’s request,
                  we may show your CV to a potential client for business
                  engagement or to current client to confirm the high level of
                  skills of our members of staff providing the services. Also,
                  to fulfil its compliance obligations, our client may need to
                  check your biography more closely before providing you with
                  the access to its protected data and facilities. Therefore, we
                  may ask you to provide more specific information, such as tax
                  number, work permission, citizenship, criminal background and
                  other additional and specific background checks required by
                  clients to provide you with an access to clients’ systems and
                  premises.
                </li>
              </ul>
            </li>
            <li>
              Business relations, based on HEU Technologies legitimate interest,
              art. 6 para. 1 f GDPR :
              <ul className="privacy-inner-list">
                <li>
                  If you are a representative of our current or potential
                  client, vendor or business partner, we may process your
                  personal data to organize the approval, processing and signing
                  of contracts, acts, invoices and other contractual
                  documentation; to promote our new products, works and
                  services; to confirm the high level of our works and services;
                  to invite you to the meetings and events or organize them; to
                  comply with economic sanctions and export control list
                  screening, industry standards, regulators’ requirements and
                  other requirements related to anti-corruption, fraud
                  prevention and anti-money laundering.
                </li>
              </ul>
            </li>
            <li>
              Corporate and investor relations, based on HEU Technologies
              compliance obligations, art. 6 para. 1 c GDPR :
              <ul className="privacy-inner-list">
                <li>
                  ​If you are a current or potential director or top manager of
                  HEU Technologies, we may process your personal data to
                  organize your participation in corporate meetings, events,
                  business trips, to pay your remuneration, to cover related
                  expenses, to maintain our corporate records, to make
                  obligatory public disclosures and reports, to execute
                  auditor’s requests, to present the company and its management
                  during the sale process and to perform other legal
                  obligations. We also may share this information within HEU
                  Technologies, provide some of this data to banks, notary,
                  payroll agencies and legal advisors with the purpose of
                  opening of a corporate bank account or other accounts.
                </li>
                <li>
                  If you are a current or potential affiliate of HEU
                  Technologies, we may process your personal data to organize
                  your participation in corporate meetings and events, to
                  provide you with the meetings’ materials and our reports, to
                  support you in execution of your rights, to make obligatory
                  public disclosures and reports, to execute auditor’s requests
                  and to perform other legal obligations, including anti-money
                  laundering requirements and disclosure of beneficiaries.
                </li>
              </ul>
            </li>
            <li>
              Security, based on HEU Technologies legitimate interest, art. 6
              para. 1 f GDPR :
              <ul className="privacy-inner-list">
                <li>
                  ​If you are a visitor of this website, we may process your
                  personal data to keep this website secure, prevent fraud,
                  protect rights and interests of HEU Technologies and third
                  parties, protect Intellectual Property rights.
                </li>
                <li>
                  If you are a visitor of our office, we may process your
                  personal data to monitor physical access and to ensure
                  security in our office, as well as to prevent, detect and
                  investigate any crime, misconduct, incident or accident.
                </li>
              </ul>
            </li>
          </ul>
          <h6>Usage of Personal Data</h6>
          <p>
            We may process your data by way of collecting, recording,
            organizing, storing, using, structuring, adapting or altering,
            retrieving, consulting, aligning or combining, erasing or
            destructing.
          </p>
          <p>
            To the extent permitted by applicable data protection laws, your
            personal data may be transferred between various locations of HEU
            Technologies, depending on the purpose of processing. Such transfer
            and processing are possible only as reasonably necessary for the
            purposes set out in this Policy and within the scope of legitimate
            interest of HEU Technologies.
          </p>
          <p>
            We may disclose your personal data to some of our clients for
            business engagement or to confirm the high level of skills of our
            team members to auditors, agencies, supervisory authorities or to
            other external service providers on “need to know basis” to perform
            our contractual obligations.
          </p>
          <p>We may also disclose your personal data :</p>
          <ul className="privacy-page-list">
            <li>To the extent that we are required to do so by law.</li>
            <li>
              In connection with any ongoing or prospective legal proceedings.
            </li>
            <li>
              In order to establish, exercise or defend our legal rights,
              including providing information to others for the purposes of
              fraud prevention and reducing credit risk.
            </li>
          </ul>
          <h6>Controlling Your Data</h6> ​
          <p>You, as a Data Subject, have the right to :</p>
          <ul className="privacy-page-list">
            <li>
              Request information about whether we hold personal information
              about you, and, if so, what that information is and why we are
              holding or using it.
            </li>
            <li>
              Request access to your personal information. This enables you to
              receive a copy of the personal information we hold about you and
              to check that we are lawfully processing it.
            </li>
            <li>
              Request correction of the personal information that we hold about
              you. This enables you to have any incomplete or inaccurate
              information we hold about you corrected.
            </li>
            <li>
              Request erasure of your personal information. This enables you to
              ask us to delete or remove personal information where there is no
              good reason for us continuing to process it. You also have the
              right to ask us to delete or remove your personal information
              where you have exercised your right to object to processing (see
              below).
            </li>
            <li>
              Object to processing of your personal information where we are
              relying on a legitimate interest (or those of a third party) and
              there is something about your situation which makes you want to
              object to processing on this ground. You also have the right to
              object where we are processing your personal information for
              direct marketing purposes.
            </li>
            <li>
              Object to automated decision-making, including profiling, that is
              not to be subject of any automated decision-making by us using
              your personal information or profiling of you.
            </li>
            <li>
              Request the restriction of processing of your personal
              information. This enables you to ask us to suspend the processing
              of personal information about you, for example if you want us to
              establish its accuracy or the reason for processing it.
            </li>
            <li>
              Request transfer of your personal information in an electronic and
              structured form to you or to another party. This enables you to
              take your data from us in an electronically useable format and to
              be able to transfer your data to another party in an
              electronically useable format.
            </li>
            <li>
              Withdraw consent. You have the right to withdraw your consent to
              the collection, processing and transfer of your personal
              information for a specific purpose at any time. Once we have
              received notification that you have withdrawn your consent, we
              will no longer process your information for the purpose or
              purposes you originally agreed to, unless we have another
              legitimate basis for doing so in law. The withdrawal of consent
              will not affect the lawfulness of processing based on consent
              prior its withdrawal.
            </li>
          </ul>
          <p>
            If you want to exercise any of these rights, please contact us at
            info@heu.ai. The request response time is 45 days.
          </p>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access the
            information or to exercise any of your other rights. This is another
            security measure to ensure that personal information is not
            disclosed to any person who has no right to receive it.
          </p>
          <p>
            Please exercise your rights wisely and note that abuse of rights may
            entail your liability.
          </p>
          <h6>Data Retention </h6>
          <p>
            We make sure to use appropriate technical and physical security
            measures to protect your personal data which is transmitted, stored
            or otherwise processed by us, from accidental or unlawful
            destruction, loss, alteration, unauthorized disclosure of, or
            access.
          </p>
          <p>
            Your personal data is securely stored by HEU Technologies on its
            servers, and on the servers of the cloud-based database management
            services that HEU Technologies engages, such as Workable.
          </p>
          <p>
            We will not retain your data for longer than it is necessary to
            fulfil the purpose it is being processed for. To determine the
            appropriate retention period, we consider the amount, nature and
            sensitivity of the personal data, the purposes for which we process
            it and whether we can achieve those purposes through other means. We
            must also consider periods for which we might need to retain
            personal data in order to meet our legal obligations or to deal with
            complaints, queries and to protect our legal rights in the event of
            a claim being made. When we no longer need your personal data, we
            will securely delete or destroy it.
          </p>
          <p>
            We will retain information and documents, including electronic
            documents, containing personal data :
          </p>
          <ul className="privacy-page-list">
            <li>To the extent that we are required to do so by law.</li>
            <li>
              If the information is relevant to any ongoing or prospective legal
              proceedings, and in order to establish, exercise or defend our
              legal rights, including providing information to others for the
              purposes of fraud prevention and reducing credit risk.
            </li>
          </ul>
          <h6>Cookies </h6>
          <p>
            When you visit the HEU Technologies website, we may place cookies
            and similar analytical codes (collectively, “Cookies”) on your
            device, browser or the webpage you are viewing, in order to
            personalize your experience, understand usage patterns and provide,
            improve, and secure the HEU Technologies websites. Cookies are
            simple computer files made of text. Cookies do not typically contain
            any information that personally identifies someone, but personal
            data that we store about you may be linked to the information
            obtained from cookies.
          </p>
          <p>
            Our websites use different types of Cookies for different reasons,
            as follows :
          </p>
          <ul className="privacy-page-list">
            <li>
              Functional cookies - these Cookies are essential to enable you to
              move around the HEU Technologies websites and use their features.
              These Cookies make sure you can view the websites and use them in
              a proper way. They also give you access to secured parts of the
              HEU Technologies websites.
            </li>
            <li>
              Analytical cookies and other cookies - these Cookies help us
              improve all our websites, collect anonymous information about how
              visitors use our websites, collect information about the most
              visited pages and tell us whether and how many error messages were
              displayed.
            </li>
            <li>
              Third-party cookies - these Cookies help third parties to help
              track and manage the effectiveness of, for example, their
              websites, ads, number of visitors. More information about these
              Cookies may be available on the relevant third party’s website.
            </li>
          </ul>
          <p>
            Based on how long they are valid, the Cookies on the HEU
            Technologies websites may be either persistent cookies or session
            cookies: a persistent cookie will be stored by a web browser and
            will remain valid until its set expiry date, unless deleted by the
            user before the expiry date; a session cookie, on the other hand,
            will expire at the end of the user session, when the web browser is
            closed. If you want to receive more information about the cookies we
            use, please contact us.
          </p>
          <p>
            You can adjust your browser settings to delete some of our cookies
            or cookies set by third parties. You may also adjust your browser
            settings to prevent websites from setting cookies or third-party
            cookies altogether. If you prevent us from setting specific cookies,
            you may find that some functions are not available or that certain
            parts of the website will not load.
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
