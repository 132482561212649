import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import ClassificationBefore from "../../../assets/video/video_classification_before.mp4";
import ClassificationAfter from "../../../assets/video/video_classification_after.mp4";

const Classification = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Classify using the following attributes."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createCategorizationTask({
`}
<code>{`callback_url`}</code>:
{`  'http://www.example.com/callback',
`}
<code>{`instruction`}</code>:
{`  'Classify using the following attributes.',
`}
<code>{`attachment_type`}</code>:
{`  'video',
`}
<code>{`attachment`}</code>:
{`  'http://example.com/video.mp4',
`}
<code>{`taxonomies`}</code>:
{`  {
`}
<code>{`    category`}</code>:
{`  {
`}
<code>{`        type`}</code>: {`'category',
`}
<code>{`        description`}</code>: {`'Video Category',
`}
<code>{`        choices`}</code>: {`['Sports', 'Lifestyle', 'Job']
    },
`}
    <code>{`adventure-genre`}</code>:
    {` {
    `}
    <code>{`type`}</code>: {` 'category',
    `}
    <code>{`description`}</code>:{` 
        'What sub-category of lifestyle 
    does it belong to?',
    `}
    <code>{`choices`}</code>:{` 
        ['Cooking', 'Exercise', 'Arts & Crafts'],
    `}
    <code>{`condition`}</code>:{`{
    `}<code>{`      taxonomy`}</code>:{` 'category',
    `}
    <code>{`      choices`}</code>:{` ['Exercise']
            }
          },
        }
      },
      (err, task) => {
        // do something with task
      }
    );
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <video src={ClassificationAfter} autoPlay muted loop />
          ) : (
            <video src={ClassificationBefore} autoPlay muted loop />
          )}
        </div>
      </div>
    </div>
  );
};

export default Classification;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "annotations": {
    "url": 
      "https://heu.ai/swvgwgv5747avav5a8va8v5a9v",
  },
}      
`}
        </pre>
      </div>
    </div>
  );
};
