import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import CuboidBefore from "../../../assets/video/video_cuboid_before.mp4";
import CuboidAfter from "../../../assets/video/video_cuboid_after.mp4";

const BoundingBox = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Draw a cuboid around vehicles."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createVideoannotationTask({
`}
        <code>{`callback_url:`}</code>
        {`  
  'http://www.example.com/callback',
`}
        <code>{`instruction:`}</code>
        {`
'Draw a cuboid around vehicles. Predict where occluded.',
`}
        <code>{`objects_to_annotate:`}</code>
        {`
['vehicle'],
`}
        <code>{`attachement_type:`}</code>
        {`  video,
`}
        <code>{`attachment:`}</code>
        {`  'http://example.com/video.mp4',
`}
        <code>{`min_width:`}</code>
        {`  30,
`}
        <code>{`min_height:`}</code>
        {`  30,
,(err, task) => {
      // do something with task
});
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <video src={CuboidAfter} autoPlay muted loop />
          ) : (
            <video src={CuboidBefore} autoPlay muted loop />
          )}
        </div>
      </div>
    </div>
  );
};

export default BoundingBox;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "annotations": {
    "url": 
      "https://heu.ai/245s2dfshg46",
  },
}      
`}
        </pre>
      </div>
    </div>
  );
};
