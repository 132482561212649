import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import BoundingBoxBefore from "../../../assets/images/boundingbox.jpg";
import BoundingBoxAfter from "../../../assets/images/boundingboxrn.jpg";

const BoundingBox = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Draw a box around each rooftop and boat."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createAnnotationTask({
`}
            <code>{`callback_url:`}</code>
            {`  
  'http://www.example.com/callback',
`}
            <code>{`instruction:`}</code>
            {`
  'Draw a box around each rooftop and boat.',
`}
            <code>{`objects_to_annotate:`}</code>
            {`
  ['boat', 'rooftop'],
`}
            <code>{`with_labels:`}</code>
            {`  true,
`}
            <code>{`min_width:`}</code>
            {`  54,
`}
            <code>{`min_height`}</code>
            {`  56
}, (err, task) => {
    // do something with task
});
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={BoundingBoxAfter} alt="" />
          ) : (
            <img src={BoundingBoxBefore} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default BoundingBox;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
    "response": {
      "annotations": [
        {
          "width": 237,
          "height": 75,
          "left": 0,
          "top": 46,
          "label": "rooftop"
        },
        {
          "width": 45,
          "height": 34,
          "left": 34,
          "top": 0,
          "label": "rooftop"
        },
        ...
      ]
    },
    "task_id": "337dfhn4eyw4cfne4y",
    "task": { ... }
}              
`}
        </pre>
      </div>
    </div>
  );
};
