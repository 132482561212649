import React, { useEffect } from "react";
import "./Products.css";
import ScaleText from "../../assets/images/scale-text.jpg";
import { Tabs } from "antd";
import Classification from "./TextCodes/Classification";
import NER from "./TextCodes/NER";

import ReactGA from "react-ga";

const { TabPane } = Tabs;

const Text = () => {

  useEffect(() => {
    document.title = "Products";

    ReactGA.pageview(window.location);
  }, []);

  const topData = [
    {
      title: "Named Entity Recognition",
      text:
        "NER is an information extraction technique that automatically identifies named entities in a text and classifies them into predefined categories.",
    },
    {
      title: "Classification",
      text:
        "Applies a classification algorithm to identify shared characteristics of certain classes.",
      list: [
        "Search and Ad Relevance",
        "Recommendation Systems",
        "Precision Agrigulture",
        "Policy Enforcement",
      ],
    },
  ];

  const topCards = topData.map((card) => {
    return (
      <div className="col-12 col-md-4 top-card-outer">
        <div className="top-card-inner-big top-card-inner">
          <h4>{card.title}</h4>
          <p>{card.text}</p>
          {card.list ? (
            <ul>
              {card.list.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="text-banner"
        className="container-fluid products-banner"
      ></section>
      <section className="container-fluid products-content">
        <div className="container-xl">
          <h1>
            <img src={ScaleText} alt="" />
            <span style={{ fontWeight: "400" }}>Heu</span> Text
          </h1>
          <p>The comprehensive annotation platform for Text.</p>
          <div className="row products-row">
            <div className="col-md-2"></div>
            {topCards}
            <div className="col-md-2"></div>
          </div>
          <h1>
            <span style={{ fontWeight: "400" }}>How It</span> Works
          </h1>
          <p>
            Maximizing operational efficiency and reducing the cost of projects.
          </p>
          <div className="row products-row">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Named Entity Recognition" key="1">
                <NER />
              </TabPane>
              <TabPane tab="Classification" key="2">
                <Classification />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Text;
