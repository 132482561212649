import React, { useEffect } from "react";
import "./Solutions.css";
import AOS from "aos";
import EnvironmentPerception from "../../assets/images/solution_new/Environment-Perception.jpg";
import InventoryHandelling from "../../assets/images/solution_new/inventory-handelling.jpg";
import PredictiveMaintenance from "../../assets/images/solution_new/Predictive-Maintenance.jpg";
import QualityControl from "../../assets/images/solution_new/Quality-Control.jpg";
import LogisticsManagement from "../../assets/images/solution_new/Logistics-Management.jpg";

import Kitting from "../../assets/images/solutions/kitting.gif";
import MachineTending from "../../assets/images/solutions/machine-tending.gif";
import BinPicking from "../../assets/images/solutions/bin-picking.gif";
import Palletizing from "../../assets/images/solutions/palletizing.gif";
import Packaging from "../../assets/images/solutions/packaging.gif";
import Sorting from "../../assets/images/solutions/sorting.gif";

import ReactGA from "react-ga";

const SolutionRobotics = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 50,
    });

    document.title = "Solutions";

    ReactGA.pageview(window.location);
  }, []);

  const industrialData = [
    {
      title: "Environment Perception",
      image: EnvironmentPerception,
    },
    {
      title: "Inventory Handelling",
      image: InventoryHandelling,
    },
    {
      title: "Predictive Maintenance",
      image: PredictiveMaintenance,
    },
    {
      title: "Quality Control",
      image: QualityControl,
    },
    {
      title: "Logistics Management",
      image: LogisticsManagement,
    },
  ];

  const roboticsData = [
    {
      title: "Kitting",
      text:
        "In manufacturing and warehousing operations, kitting is the process of creating product assortments, preparing and orienting parts to simplify downstream handling operations, or consolidating several SKUs into a new standalone product.",
      image: Kitting,
    },
    {
      title: "Machine Tending",
      text:
        "Machine tending is the process of supervising and feeding parts into and out of a machine. Applications include CNC machines, injection molding machines, additive manufacturing, functional test devices, and packaging equipment.",
      image: MachineTending,
    },
    {
      title: "Bin Picking",
      text:
        "Bin picking is the process of picking an individual part from a bulk container of randomly oriented, unstructured units.",
      image: BinPicking,
    },
    {
      title: "Palletizing",
      text:
        "Ongoing palletization and depalletization, the process of stacking and organizing goods onto or off of a pallet for stable handling and transportation, is a prominent feature of nearly every supply chain and integral to manufacturing and warehousing operations.",
      image: Palletizing,
    },
    {
      title: "Packaging",
      text:
        "Packaging broadly describes the process of taking consumer-ready goods and placing them into consumer-ready packaging. Functions include, but are not limited to, package assembly, case packing, cartoning, bundling, and retail or shelf-ready displays.",
      image: Packaging,
    },
    {
      title: "Sorting",
      text:
        "Robotic sortation systems detect, and separate commingled part types based on part appearance and attributes.",
      image: Sorting,
    },
  ];

  const industrialCards = industrialData.map((item) => {
    return (
      <div
        class="project col-md-4 col-12"
        data-aos="fade"
        style={{ height: "290px" }}
      >
        <div class="project__card">
          <div class="project__image">
            <img src={item.image} alt="" />
          </div>
          <div class="project__detail">
            <h2 class="project__title">{item.title}</h2>
          </div>
        </div>
        <div class="inilab">{item.title}</div>
      </div>
    );
  });

  const roboticsCards = roboticsData.map((item) => {
    return (
      <div className="col-md-4" style={{ padding: "16px" }}>
        <div className="robotics-card" data-aos="fade">
          <div className="gif-box">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="card-info">
            <h5>{item.title}</h5>
            <p>{item.text}</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="robotics-banner"
        className="container-fluid solutions-banner"
      ></section>
      <section className="container-fluid solutions-content">
        <div className="container-xl">
          <h1>
            <span style={{ fontWeight: "400" }}>Industrial</span> Use Cases
          </h1>
          <p>
            Powering Computer Vision for manufacturing with highly accurate
            training and validation data.
          </p>
          <div className="row solutions-row">{industrialCards}</div>
          <h1>
            <span style={{ fontWeight: "400" }}>Robotics</span> Use Cases
          </h1>
          <p>
            Powering Computer Vision for manufacturing with highly accurate
            training and validation data.
          </p>
          <div className="row solutions-row">{roboticsCards}</div>
        </div>
      </section>
    </div>
  );
};

export default SolutionRobotics;
