import React, { useEffect } from "react";
import "./Solutions.css";
import AOS from "aos";
import VirtualTryOnTop from "../../assets/images/solution_new/Virtual_try_on.jpg";
import VirtualTryOnContent from "../../assets/images/solutions/virtual-tryon.jpg";
import CustomerSupport from "../../assets/images/solutions/customer-support.jpg";
import CrowdTop from "../../assets/images/solution_new/Body Detection.jpg";
import CrowdContent from "../../assets/images/solutions/crowd.jpg";
import Sentiment from "../../assets/images/solutions/sentiment.jpg";
import Shelf from "../../assets/images/solutions/shelf.jpg";
import Staff from "../../assets/images/solutions/staff.jpg";

import ReactGA from "react-ga";

const Retail = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 50,
    });

    document.title = "Solutions";

    ReactGA.pageview(window.location);
  }, []);

  const data = [
    {
      name: "Virtual Try On",
      text:
        "Virtual try on gives customers the experience of how garments look on them, with their personal avatar, using 3D body mapping technology. The body measurements are made in just one step and accurate to 98%. The avatar are based on the user's body measurements and their face.",
      topImage: VirtualTryOnTop,
      contentImage: VirtualTryOnContent,
    },
    {
      name: "Customer Support",
      text:
        "We provide full-service AI assistant that your customers actually want to use. Seamlessly automate tasks, saving priceless minutes off each interaction. Guide agents to the information needed to resolve inquiries, improving the customer and agent experience. Best of all: allow your teams to focus on higher value work, all while reducing costs.",
      topImage: CustomerSupport,
      contentImage: CustomerSupport,
    },
    {
      name: "Crowd Analytics",
      text:
        "Crowd analytics shows what is happening to an entire shopper population, in real time. This enables retail and food & beverage operators to act decisively to increase efficiency and profitability whilst improving their customers’ experience. Our solution processes live video input from commercial off the shelf cameras. The system detects pedestrian movements automatically using sophisticated artificial intelligence techniques and outputs live data about everything from flows, queues and wait times to processing times, occupancies, and asset utilisation.",
      topImage: CrowdTop,
      contentImage: CrowdContent,
    },
    {
      name: "Customer Sentiment",
      text:
        "Get helpful insights that can drive effective business decisions, strategies, and objectives. Obtain in-depth information from blogs, reviews, forums, news, and social media posts. Understand the reactions of the audience to the posts to curate a solid plan for future content and campaigns. Measure the ROI of your marketing campaign.",
      topImage: Sentiment,
      contentImage: Sentiment,
    },
    {
      name: "Shelf Management",
      text:
        "Our shelf management solution can observe which shelves are empty and where restocking is required. Naturally, this notification can make sure employees look in the right places when replenishing the merchandise. It will examine any deformities or imperfections so you can notify your supplier and handle the situation straightaway. It separates and sort your products which not only saves time but reduces extensive labour for your employees.",
      topImage: Shelf,
      contentImage: Shelf,
    },
    {
      name: "Staffing Optimization",
      text:
        "Our Staffing optimization solution allows retailers to analyse historical labour scheduling and sales data, comparing it to real-time data in order to provide more insight into staffing requirements during peak sales periods. It can then suggest how many seasonal employees to hire, in what departments they need to be staffed, and what their availability needs to be in order to maximize sales and manage the projected store workload.",
      topImage: Staff,
      contentImage: Staff,
    },
  ];

  const topCards = [];

  const contentCards = [];

  data.forEach((item) => {
    topCards.push(
      <div class="project col-md-4 col-12" data-aos="fade" style={{height : "290px"}}>
        <div class="project__card">
          <div class="project__image">
            <img src={item.topImage} alt="" />
          </div>
          <div class="project__detail">
            <h2 class="project__title">{item.name}</h2>
          </div>
        </div>
        <div class="inilab">{item.name}</div>
      </div>
    );

    contentCards.push(
      <div className="col-12 col-md-12 row content-card">
        <div className="col-12 col-md-7 content-card-info">
          <h4>{item.name}</h4>
          <p>{item.text}</p>
        </div>
        <div className="col-12 col-md-5 content-card-image">
          <img src={item.contentImage} alt={item.name} />
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="retail-banner"
        className="container-fluid solutions-banner"
      ></section>
      <section className="container-fluid solutions-content">
        <div className="container-xl">
          <h1>
            <span style={{ fontWeight: "400" }}>Retail</span> Use Cases
          </h1>
          <p>
            Powering computer vision for retail stores with highly accurate
            training data.
          </p>
          <div className="row solutions-row">{topCards}</div>
          <div className="row solutions-row">{contentCards}</div>
        </div>
      </section>
    </div>
  );
};

export default Retail;
