import React from "react";
import { Tabs } from "antd";
import Classification from "../Produts/TextCodes/Classification";
import NER from "../Produts/TextCodes/NER";

const { TabPane } = Tabs;

const Text = () => {
  return (
    <div className="row products-row">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Named Entity Recognition" key="1">
          <NER />
        </TabPane>
        <TabPane tab="Classification" key="2">
          <Classification />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Text;
