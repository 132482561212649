import React, { useState } from "react";
import { BrowserRouter as Link } from "react-router-dom";
import emailjs from "emailjs-com";
import { message } from "antd";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [messageText, setMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const resetFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setCountry("");
    setMessage("");
    setChecked(false);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitContact = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      phone: phone,
      country: country,
      message: messageText,
      checked: checked,
    };

    if (data.name === "" || data.email === "") {
      message.error("Name and Email are required");
      return;
    }

    if (!checked) {
      message.error("Please accept our privacy policy to submit the form");
      return;
    }

    if (!validateEmail(email)) {
      message.error("Please give a valid email");
      return;
    }

    emailjs
      .send(
        "service_rhjxl8x",
        "template_9f0fn1j",
        data,
        "user_cX2huY0dePul7Uvv4ScI8"
      )
      .then(
        (result) => {
          console.log(result.text);
          resetFields();
          message.success(
            "Your message has been successfully sent. We will contact you very soon!"
          );
        },
        (error) => {
          console.log(error.text);
          resetFields();
          message.error("Error in sending message, please try again");
        }
      );
  };

  return (
    <>
      <section id="contact-form">
        <div className="container form-box">
          <h1 className="text-center">
            <span style={{ fontWeight: "400" }}>Ready To Get </span>Started
          </h1>
          <p>Contact our team to get more information</p>
          <form className="form row m-auto" onSubmit={submitContact}>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Email ID"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                name="phone"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                value={phone}
              />
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Country"
                name="country"
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
                value={country}
              />
            </div>
            <div className="form-group col-md-12">
              <textarea
                className="form-control form-text"
                placeholder="Message"
                name="message"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={messageText}
                style={{ height: "110px" }}
                rows="3"
              ></textarea>
            </div>
            <div
              class="form-group form-check col-md-12"
              style={{ marginTop: "-10px" }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
              <label
                class="form-check-label"
                for="exampleCheck1"
                style={{ fontSize: "11px" }}
              >
                By sending this form I confirm that I have read and accept&nbsp;
                <Link to="/privacy-policy">Privacy Policy</Link>
              </label>
            </div>
            <button type="submit" className="btn btn-primary contact-button">
              Send Message
            </button>
          </form>
        </div>
      </section>
      <div
        style={{
          width: "100%",
          height: "350px",
          backgroundColor: "#f5f8ff",
          position: "absolute",
          zIndex: "-50",
          marginTop: "-150px",
        }}
      ></div>
    </>
  );
};

export default ContactForm;
