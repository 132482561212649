import React, { useEffect } from "react";
import "./Training.css";
import { Tag, Collapse } from "antd";
import {
  ClockCircleOutlined,
  FileDoneOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TrainingForm from "./TrainingForm";
import LearnFromUs from "./LearnFromUs";

import ReactGA from "react-ga";

const { Panel } = Collapse;

const tagStyle = {
  color: "black",
  padding: "6px 8px",
  borderRadius: "6px",
  fontSize: "14px",
  margin: "6px",
};

const DataScience = () => {

  useEffect(() => {
    document.title = "Training";

    ReactGA.pageview(window.location);
  }, []);

  return (
    <div className="page-home">
      <section
        className="training-banner container-fluid"
        id="data-science-banner"
      >
        <div className="container-xl row m-auto">
          <div className="col-md-6 training-info">
            <h1>Course 2 : Data Science</h1>
            <p>
              Working with data is an essential part of maintaining a healthy
              business. This course will introduce you to the field of data
              science, help you understand the various processes and distinguish
              between terms such as: ‘traditional data,’ ‘big data,’ ‘business
              intelligence,’ ‘business analytics,’ ‘data analytics,’ ‘data
              science,’ and ‘machine learning.’
            </p>
            <div className="training-tags">
              <Tag
                style={tagStyle}
                icon={
                  <ClockCircleOutlined
                    style={{ color: "#f8b63a", fontSize: "22px" }}
                  />
                }
              >
                20 Hours
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <ProjectOutlined
                    style={{ color: "green", fontSize: "22px" }}
                  />
                }
              >
                25 Exercises
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <FileDoneOutlined
                    style={{ color: "blue", fontSize: "22px" }}
                  />
                }
              >
                4 Assignments
              </Tag>
            </div>
          </div>
          <div className="training-form-div col-md-6">
            <TrainingForm />
          </div>
        </div>
      </section>
      <section className="course-content container-fluid">
        <div className="container-xl m-auto">
          <h5>Course Curriculum</h5>
          <Collapse
            style={{ backgroundColor: "#f7f7f7" }}
            expandIcon={() => {
              return null;
            }}
          >
            <Panel header="Module 1 : Introduction to Data Science" key="1">
              <ul>
                <li>What is Data Science</li>
                <li>Application of Data Science</li>
                <li>Assigning Data Science Project</li>
                <li>Data Science Roles & Tools</li>
              </ul>
            </Panel>

            <Panel
              header="Module 2 : Basic Tools and Knowledge to Work"
              key="2"
            >
              <ul>
                <li>
                  Introduction to Python
                  <ul>
                    <li>Environment Set-Up</li>
                    <li>Jupyter overview</li>
                    <li>Python Numpy</li>
                    <li>Python Pandas</li>
                    <li>Python Matplotlib</li>
                  </ul>
                </li>
                <li>
                  Introduction to Statistics
                  <ul>
                    <li>Important statistical concepts used in data science</li>
                    <li>Difference between population and sample</li>
                    <li>Types of variables</li>
                    <li>Measures of central tendency</li>
                    <li>Measures of variability</li>
                    <li>Coefficient of variance</li>
                    <li>Skewness and Kurtosis</li>
                  </ul>
                </li>
                <li>
                  Introduction to Machine Learning
                  <ul>
                    <li>Linear regression and regularization</li>
                    <li>Model selection and evaluation</li>
                    <li>Classification: kNN, decision trees</li>
                    <li>Classification: SVM</li>
                    <li>Ensemble methods: random forests</li>
                    <li>
                      Intro to probability: Naïve Bayes and logistic regression
                    </li>
                    <li>Feature engineering and selection</li>
                    <li>Clustering: k-means, hierarchical clustering</li>
                    <li>Dimensionality reduction: PCA and SVD</li>
                    <li>Text mining</li>
                  </ul>
                </li>
              </ul>
            </Panel>

            <Panel header="Module 3 : Data Collection and Storage" key="3">
              <ul>
                <li>Data Sources</li>
                <li>Data types</li>
                <li>Classifying Data types</li>
                <li>Data storage & retrieval</li>
                <li>Cloud Platforms</li>
                <li>Data pipelines</li>
                <li>Data pipelines characteristics</li>
                <li>Extract transform load</li>
              </ul>
            </Panel>

            <Panel
              header="Module 4 : Preparation, Exploration and Visualization"
              key="4"
            >
              <ul>
                <li>Data preparation</li>
                <li>
                  EDA: Exploratory Data analysis
                  <ul>
                    <li>Numerical EDA</li>
                    <li>Visual EDA</li>
                  </ul>
                </li>
                <li>Visualization</li>
              </ul>
            </Panel>

            <Panel header="Module 5 : Experimentation and Predictation" key="5">
              <ul>
                <li>A/B testing</li>
                <li>A/B testing workflow</li>
                <li>Time series forecasting</li>
                <li>Classifying time-series data</li>
                <li>Interpret a time series plot</li>
                <li>Supervised Machine Learning</li>
                <li>Features and Labels</li>
                <li>Cluster Size selection</li>
              </ul>
            </Panel>

            <Panel header="Module 6 : Advanced Technique" key="6">
              <ul>
                <li>Big Data</li>
                <li>Big data storage</li>
                <li>Advanced: neural networks and deep learning</li>
              </ul>
            </Panel>
          </Collapse>
        </div>
      </section>
      <LearnFromUs />
    </div>
  );
};

export default DataScience;
