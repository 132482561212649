import React, { useEffect } from "react";
import { Tooltip } from "antd";
import HariHeu from "../assets/images/hari-heu.jpg";
import HarshalHeu from "../assets/images/harshal-heu.jpg";
import PavanHeu from "../assets/images/pavan-heu.jpg";
import SayoojImg from "../assets/images/sayooj.png";
import VivekImg from "../assets/images/vivek.jpeg";

/*members*/
import Karthika from "../assets/images/karthika.jpg";
import Siddhesh from "../assets/images/siddhesh.jpg";
import Smita from "../assets/images/smita.jpg";
import Sarath from "../assets/images/sarath.jpg";
import Ashwin from "../assets/images/ashwin.jpg";
import Dipali from "../assets/images/dipali.jpg";
import Priyansh from "../assets/images/priyansh.jpg";

import Default from "../assets/images/team-img.jpg";

import ReactGA from "react-ga";

const About = () => {
  const directors = [
    {
      name: "Harikrishnan VK",
      designation: "Chief Executive Officer, Co-Founder",
      image: HariHeu,
    },
    // {
    //   name: "Harshal Deore",
    //   designation: "Chief Product Officer",
    //   image: HarshalHeu,
    // },
    {
      name: "Pavan Raju",
      designation: "Chief Research Officer, Co-Founder",
      image: PavanHeu,
    },
    {
      name: "Dr. Vivek Jaglan",
      designation: "Senior Consultant, AI Strategy",
      image: VivekImg,
    },
  ];

  const consultants = [
    {
      name: "Sayooj PS",
      designation: "Senior Consultant, Technical Architect",
      image: SayoojImg,
    },
    {
      name: "Harshal Deore",
      designation: "Senior Data Scientist",
      image: HarshalHeu,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About Us";

    ReactGA.pageview(window.location);
  }, []);

  // const members = [
  //   {
  //     name: "Siddhesh Ghadi",
  //     image: Siddhesh,
  //   },
  //   {
  //     name: "Priyansh Rawal",
  //     image: Priyansh,
  //   },
  //   {
  //     name: "Smita Gupta",
  //     image: Smita,
  //   },
  //   {
  //     name: "Karthika Menon",
  //     image: Karthika,
  //   },
  //   {
  //     name: "Dipali Balas",
  //     image: Dipali,
  //   },
  //   {
  //     name: "Sarath Pillai",
  //     image: Sarath,
  //   },
  //   {
  //     name: "Ashwin Sajith",
  //     image: Ashwin,
  //   },
  //   {
  //     name: "Raj Malhotra",
  //     image: Default,
  //   },
  // ];

  return (
    <div className="page">
      <div className="container-fluid" id="about-banner"></div>
      <div className="container-fluid" id="about-top-section">
        <div className="container">
          <h1>
            <span style={{ fontWeight: "400" }}>About</span> Us
          </h1>
          <p>
            At Heu, we aim to deliver a platform for Artificial intelligence
            enabling enterprises to enhance their business and solve challenging
            problems. We evaluate each business idea and come up with the best
            proposals that add value to their customers and clients using AI.
          </p>
          <p>
            Our competent team of AI developers and Data Scientists work around
            the clock in our R&D section to ensure business ideas are
            transformed to end products. We provide consultancy services to
            clients, helping them to design and implement their ideas using AI
            solutions. We help in building an AI strategy and provide awareness
            about the capabilities of AI and their use cases to our clients.​
          </p>
          {/* <p>
            Founders of the organization believe in enabling people and society
            at large with the capabilities of AI. For this specific purpose, we
            have a separate motivated team of trainers to conduct workshops at
            corporate offices, schools, and universities for students and
            professionals.
          </p> */}
        </div>
        <div
          className="container product-tab-list"
          style={{ borderRadius: "5px" }}
        >
          <h1>
            <span style={{ fontWeight: "400" }}>What is</span> Heu
          </h1>
          <p style={{ textAlign: "center" }}>
            {" "}
            <b>Heu is short for heuristic. </b>
          </p>
          <p style={{ maxWidth: "360px", margin: "auto", textAlign: "center" }}>
            Meaning of heuristic: "Enabling someone to discover or learn
            something for themselves."
          </p>
        </div>
        <div
          data-aos="fade-up"
          class="bg-white container-fluid space-top-desktop"
        >
          <section class="ps-timeline-sec pt-5">
            <div className="container">
              <h1>
                <span
                  style={{
                    fontWeight: "400",
                    display: "inline-block",
                    padding: "0px",
                  }}
                >
                  Core
                </span>{" "}
                Team
                <p style={{ textAlign: "center", fontWeight: "100" }}>
                  Meet our team members
                </p>
              </h1>
              <div
                className="container director-tiles row m-auto"
                style={{ maxWidth: "950px" }}
              >
                {directors.map((item) => {
                  return (
                    <div className="col-md-4 director-card-outer">
                      <div className="director-card-inner">
                        <img src={item.image} alt="" />
                        <h4>{item.name}</h4>
                        <p>{item.designation}</p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="container director-tiles row m-auto"
                style={{ maxWidth: "500px" }}
              >
                {consultants.map((item) => {
                  return (
                    <div className="col-md-6 director-card-outer">
                      <div className="director-card-inner">
                        <img src={item.image} alt="" />
                        <h4>{item.name}</h4>
                        <p>{item.designation}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <div className="container-fluid" id="about-bottom-section">
        <h1>
          <span style={{ fontWeight: "400" }}>Our</span> Team
        </h1>
        <p>Our Team is our Strength</p>
        <div className="container row m-auto">
          {members.map((item, index) => {
            if (index < members.length) {
              return (
                <div className="col-6 col-md-3 team-member">
                  <Tooltip placement="topLeft" title={item.name}>
                    <img src={item.image} alt="" />
                  </Tooltip>
                </div>
              );
            } else {
              return (
                <div className="col-3 col-md-3 team-member">
                  <Tooltip placement="topLeft" title={item.name}>
                    <img src={item.image} alt="" />
                  </Tooltip>
                </div>
              );
            }
          })}
        </div>
      </div> */}
    </div>
  );
};

export default About;
