import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import Slider1 from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
//import "normalize.css/normalize.css";
import "./HomeAnimation.css";
import "./Home.css";
import "aos/dist/aos.css";
import AOS from "aos";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../style.css";
import "./Home.css";
import Slider from "react-slick";
import ClientImage from "../assets/images/client-img.png";
import locationIcon from "../assets/images/location.png";
import TextyAnim from "rc-texty";
import "rc-texty/assets/index.css";
import timelineMobile from "../assets/images/timeline-mobile.png";
import mapMobile from "../assets/images/map-mobile.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Banners
import HomeBanner1 from "../assets/images/home-banner-1.gif";
import HomeBanner2 from "../assets/images/home-banner-2.gif";
import HomeBanner3 from "../assets/images/home-banner-3.gif";
import HomeBanner4 from "../assets/images/home-banner-4.gif";

//Company logos
import AmbujaCement from "../assets/images/logos-dark/Ambuja Cement.png";
import AmulDairy from "../assets/images/logos-dark/Amul Dairy.png";
import ApolloTyres from "../assets/images/logos-dark/Apollo Tyres.png";
import Bosch from "../assets/images/logos-dark/Bosch.png";
import CEAT from "../assets/images/logos-dark/CEAT.png";
import Hitachi from "../assets/images/logos-dark/Hitachi.png";
import Honda from "../assets/images/logos-dark/Honda.png";
import LT from "../assets/images/logos-dark/Lt.png";
import Panasonic from "../assets/images/logos-dark/Panasonic.png";
import Samsung from "../assets/images/logos-dark/Samsung.png";

//Programming Languages
import Python from "../assets/images/tools/python.jpg";
import Java from "../assets/images/tools/java.jpg";
import JavaScript from "../assets/images/tools/javascript.jpg";
import Matlab from "../assets/images/tools/matlab.jpg";
import Cpp from "../assets/images/tools/c++.jpg";
import Csharp from "../assets/images/tools/csharp.jpg";
import Php from "../assets/images/tools/php.jpg";
import VBDotNet from "../assets/images/tools/vbdotnet.jpg";
import SQL from "../assets/images/tools/sql.jpg";

//Tools and Frameworks
import Tensorflow from "../assets/images/tools/tensor-flow.jpg";
import Pytorch from "../assets/images/tools/pytorch.jpg";
import Keras from "../assets/images/tools/keras.jpg";
import CNTK from "../assets/images/tools/cntk.jpg";
import Theano from "../assets/images/tools/theano.jpg";
import Node from "../assets/images/tools/nodejs.jpg";
import Angular from "../assets/images/tools/angularjs.jpg";
import Django from "../assets/images/tools/django.jpg";
import Flask from "../assets/images/tools/flask.jpg";
import Spark from "../assets/images/tools/spark.jpg";
import Hadoop from "../assets/images/tools/hadoop.jpg";
import Storm from "../assets/images/tools/apache-storm.jpg";

//Databases
import PostgreSQL from "../assets/images/tools/postgreSQL.jpg";
import Firebase from "../assets/images/tools/firebase.jpg";
import DynamoDB from "../assets/images/tools/dynamodb.jpg";
import RedShift from "../assets/images/tools/redshift.jpg";
import MongoDB from "../assets/images/tools/mongodb.jpg";
import Oracle from "../assets/images/tools/oracle.jpg";

//Devops
import Azure from "../assets/images/tools/azure.jpg";
import AWS from "../assets/images/tools/aws.jpg";
import Google from "../assets/images/tools/google.jpg";
import Docker from "../assets/images/tools/docker.jpg";
import Gradle from "../assets/images/tools/gradle.jpg";

//TimeLine
import BusinessUnderstanding from "../assets/images/heu-approach-business-understanding.jpg";
import DataUnderstanding from "../assets/images/heu-approach-data-understanding.jpg";
import DataCleaning from "../assets/images/heu-approach-data-cleaning.jpg";
import DataEnhancing from "../assets/images/heu-approach-data-enhancing.jpg";
import ModellingEvaluation from "../assets/images/heu-approach-modelling-evaluvation.jpg";
import Deployment from "../assets/images/heu-approach-deployement.jpg";

//Client Logos
import BanyanPro from "../assets/images/client's_logo/banyan-pro.png";
import BeeInTheKnow from "../assets/images/client's_logo/beintheknow.png";
import RocMob from "../assets/images/client's_logo/rocmob.png";
import Multiverse from "../assets/images/client's_logo/multiverse.jpg";
import Monad from "../assets/images/client's_logo/MonadInfoTech.png";
import MakeIt from "../assets/images/client's_logo/makeitsimple.jpg";
import Comportment from "../assets/images/client's_logo/Comportment.png";

//Products Images
import ProductImage from "../assets/images/scale-img.jpg";
import ProductVideo from "../assets/images/scale-video.jpg";
import ProductDocument from "../assets/images/scale-doc.jpg";
import ProductText from "../assets/images/scale-text.jpg";

import ContactForm from "./ContactForm";
import Image from "./HomeProductTabs/Image";
import Video from "./HomeProductTabs/Video";
import Document from "./HomeProductTabs/Document";
import Text from "./HomeProductTabs/Text";

//Client images
import MakeItSimpleClient from "../assets/images/photo/Andy_Jones-make_it_simple.png";
import ComportmentClient from "../assets/images/photo/anoop_Ramachandra-comportment.png";
import MultiverseClient from "../assets/images/photo/Anubhav_Jain-multiverse.png";
import MonadClient from "../assets/images/photo/arpan_patel-monadinfotech.png";
import RocmobClient from "../assets/images/photo/avi_agrawal-rocmob.png";
import BanyanClient from "../assets/images/photo/Venkatesh_Radhakrishnan-banyantree.png";
import BeeInTheKnowClient from "../assets/images/photo/tyson.png";

import ReactGA from "react-ga";

import { Tabs } from "antd";

const { TabPane } = Tabs;

const Testimonials = [
  {
    image: MakeIt,
    text: "The system works but the special ingredient is the customer service team, they are always available and helped a lot during the setup process.",
    name: "Andy Jones",
    designation: "CEO, Make It Simple",
    clientImage: MakeItSimpleClient,
  },
  {
    image: Comportment,
    text: "Extremely satisfied! Heu's products are of best quality. Support from the team for integration made it easy for us. Would definetly recommend them.",
    name: "Anoop Ramchandra",
    designation: "Director, Comportment",
    clientImage: ComportmentClient,
  },
  {
    image: BeeInTheKnow,
    text: "Great communication and turnaround time. Code works exactly as desired.",
    name: "Tyson M. Begley",
    designation: "CEO, Be in the Know",
    clientImage: BeeInTheKnowClient,
  },
  {
    image: BanyanPro,
    text: "We have been doing business with Heu for a year now and our experience was amazing. The team comes up with great solutions everytime we approach them. We would definitely recommend them.",
    name: "Venkatesh Radhakrishnan",
    designation: "Traning Head, Banyan Pro",
    clientImage: BanyanClient,
  },
  {
    image: RocMob,
    text: "Heu team spends time to make sure they understood what we want to do, spends time in strategizing how it will be done and than completed the project before time.",
    name: "Ashwini Agarwal",
    designation: "Co-Founder, Rocmob",
    clientImage: ClientImage,
  },
  {
    image: Multiverse,
    text: "The team at Heu is very hardworking and punctual. Not just they delivered the product in time but the service we got post the delivery is praiseworthy. Would definitely recommend them to anyone.",
    name: "Anubhav Jain",
    designation: "CEO, Multiverse Tech",
    clientImage: MultiverseClient,
  },
  {
    image: Monad,
    text: " Heu has gone over and above what was expected. We can't praise them enough. We would definitely recommend them to anyone.",
    name: "Arpan Patel",
    designation: "CEO, Monad Infotech",
    clientImage: MonadClient,
  },
];

const animate = {
  position: "absolute",
  left: "0px",
};

const Home = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 800,
    });

    // document.getElementById("Dot0").classList.add("active");

    window.scrollTo(0, 0);
    document.title =
      "Heu.ai - Enhance Your Business with Enterprise AI Adoption";

    ReactGA.pageview(window.location);
  }, []);

  const [productTab, setProductTab] = useState(1);
  const [slideNumber, setSlideNumber] = useState(1);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 700,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonialCards = Testimonials.map((item) => {
    return (
      <div className="testimonial-card">
        <div className="testimonial-card-inner">
          <div>
            <img src={item.image} alt="" />
            <p>{item.text}</p>
          </div>
          <img src={item.clientImage} className="client-image" alt="" />
          <h5>{item.name}</h5>
          <h6>{item.designation}</h6>
        </div>
      </div>
    );
  });

  const getSplit = (e) => {
    const t = e.split(" ");
    const c = [];
    t.forEach((str, i) => {
      c.push(<span key={`${str}-${i}`}>{str}</span>);
      if (i < t.length - 1) {
        c.push(<span key={` -${i}`}> </span>);
      }
    });
    return c;
  };

  const setSlide = (i) => {
    if (slideNumber === 1) {
      document.getElementById(`Slide1`).classList.remove("current");
      document.getElementById(`Slide2`).classList.remove("next");
      document.getElementById(`Slide3`).classList.remove("hidden");
      document.getElementById(`Slide4`).classList.remove("previous");
      document.getElementById(`Slide1`).classList.add("animateOut", "next");
      document.getElementById(`Dot0`).classList.remove("active");
    }
    if (slideNumber === 2) {
      document.getElementById(`Slide2`).classList.remove("current");
      document.getElementById(`Slide3`).classList.remove("next");
      document.getElementById(`Slide4`).classList.remove("hidden");
      document.getElementById(`Slide1`).classList.remove("previous");
      document.getElementById(`Slide2`).classList.add("animateOut", "next");
      document.getElementById(`Dot1`).classList.remove("active");
    }
    if (slideNumber === 3) {
      document.getElementById(`Slide3`).classList.remove("current");
      document.getElementById(`Slide4`).classList.remove("next");
      document.getElementById(`Slide1`).classList.remove("hidden");
      document.getElementById(`Slide2`).classList.remove("previous");
      document.getElementById(`Slide3`).classList.add("animateOut", "next");
      document.getElementById(`Dot2`).classList.remove("active");
    }
    if (slideNumber === 4) {
      document.getElementById(`Slide4`).classList.remove("current");
      document.getElementById(`Slide1`).classList.remove("next");
      document.getElementById(`Slide2`).classList.remove("hidden");
      document.getElementById(`Slide3`).classList.remove("previous");
      document.getElementById(`Slide4`).classList.add("animateOut", "next");
      document.getElementById(`Dot3`).classList.remove("active");
    }
    // if(i>slideNumber){
    //   document.getElementById(`Slide${slideNumber}`).classList.add("animateOut", "previous");
    // }
    if (i === 1) {
      document.getElementById(`Slide1`).classList.add("animateIn", "next");
      document.getElementById(`Slide1`).classList.add("current");
      document.getElementById(`Slide2`).classList.add("next");
      document.getElementById(`Slide3`).classList.add("hidden");
      document.getElementById(`Slide4`).classList.add("previous");
      document.getElementById(`Dot0`).classList.add("active");
    }
    if (i === 2) {
      document.getElementById(`Slide2`).classList.add("animateIn", "next");
      document.getElementById(`Slide2`).classList.add("current");
      document.getElementById(`Slide3`).classList.add("next");
      document.getElementById(`Slide4`).classList.add("hidden");
      document.getElementById(`Slide1`).classList.add("previous");
      document.getElementById(`Dot1`).classList.add("active");
    }
    if (i === 3) {
      document.getElementById(`Slide3`).classList.add("animateIn", "next");
      document.getElementById(`Slide3`).classList.add("current");
      document.getElementById(`Slide4`).classList.add("next");
      document.getElementById(`Slide1`).classList.add("hidden");
      document.getElementById(`Slide2`).classList.add("previous");
      document.getElementById(`Dot2`).classList.add("active");
    }
    if (i === 4) {
      document.getElementById(`Slide4`).classList.add("animateIn", "next");
      document.getElementById(`Slide4`).classList.add("current");
      document.getElementById(`Slide1`).classList.add("next");
      document.getElementById(`Slide2`).classList.add("hidden");
      document.getElementById(`Slide3`).classList.add("previous");
      document.getElementById(`Dot3`).classList.add("active");
    }

    setTimeout(() => {
      document
        .getElementById(`Slide${i}`)
        .classList.remove("animateIn", "next");
      document
        .getElementById(`Slide${slideNumber}`)
        .classList.remove("animateOut", "next");
    }, 2000);
    setSlideNumber(i);
  };

  const content = [
    {
      title: "Enhance Your Business with Enterprise AI Adoption.",
      title1: "Enhance Your Business",
      title2: "with Enterprise AI",
      title3: "Adoption.",
      description:
        "Unlock the potential of AI with state-of-the-art products and tools to make AI real, scalable, and trust worthy",
    },
    {
      title: "Users get superior results, tailored features with lower cost.",
      title1: "Users get superior",
      title2: "results, tailored features",
      title3: "with lower cost.",
      description:
        "We help you personalize the solution to meet your exact business needs.",
    },
    {
      title: "Deploy your AI powered product at various Platforms.",
      title1: "Deploy your AI powered",
      title2: "product at various",
      title3: "Platforms.",
      description:
        "Our team has expertise in cross platform development and DevOps to deploy all cloud service providers.",
    },
    {
      title:
        "Expert training in AI & ML to build and strengthen your business.",
      title1: "Expert training in AI & ML",
      title2: "to build and strengthen",
      title3: "your business.",
      description:
        "Your employees know your use cases, infrastructure, and stack. We provide training to fill in the gaps, enabling your organization to capitalize on the potential already working under your roof.",
    },
  ];

  const settingsLogo = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 7,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 6,
        },
      },

      {
        breakpoint: 568,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  return (
    <div className="page-home">
      <Carousel
        autoplay={1000}
        autoplaySpeed={3000}
        effect="fade"
        style={window.innerWidth > 900 ? { marginTop: "-82px" } : {}}
        beforeChange={() => {
          document
            .getElementById("banner-1")
            .style.setProperty("Top", "1000px");

          document
            .getElementById("banner-2")
            .style.setProperty("Top", "1000px");

          document
            .getElementById("banner-3")
            .style.setProperty("Top", "1000px");

          document
            .getElementById("banner-4")
            .style.setProperty("Top", "1000px");

          AOS.refreshHard();
        }}
        afterChange={() => {
          document.getElementById("banner-1").style.setProperty("Top", "0px");
          document.getElementById("banner-2").style.setProperty("Top", "0px");
          document.getElementById("banner-3").style.setProperty("Top", "0px");
          document.getElementById("banner-4").style.setProperty("Top", "0px");

          AOS.refreshHard();
        }}
      >
        <div className="container-fluid home-banner">
          <section className="container-xl row m-auto p-0">
            <div className="col-md-6 m-0 home-banner-text">
              <p id="banner-1" style={{ ...animate }}></p>
              <div className="carousel-slide space-top">
                <div>
                  <h1
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-1"
                    data-aos-anchor-placement="top-bottom"
                  >
                    {/* <TextyAnim
                      className="content"
                      type={"bottom"}
                      split={getSplit}
                      delay={1000}
                      interval={30}
                    >
                      Enhance Your Business with Enterprise AI Adoption.
                    </TextyAnim> */}
                    Enhance Your Business with Enterprise AI Adoption.
                  </h1>
                  <p
                    data-aos="flip-up"
                    data-aos-anchor="#banner-1"
                    data-aos-anchor-placement="top-bottom"
                    style={{ textAlign: "left" }}
                    data-aos-delay="50"
                  >
                    Unlock the potential of AI with state-of-the-art products
                    and tools to make AI real, scalable, and trust worthy
                  </p>
                  <Link
                    style={{ float: "left" }}
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-1"
                    data-aos-anchor-placement="top-bottom"
                    className="banner-button"
                    type="button"
                    to="/contact"
                    // onClick={()=>props.setPath()}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 m-0 mob-hide">
              <img
                className="home-banner-video-img"
                src={HomeBanner1}
                alt=""
                id="home-banner-1"
              />
            </div>
          </section>
        </div>
        <div className="container-fluid home-banner">
          <section className="container-xl row m-auto p-0">
            <div className="col-md-6 home-banner-text">
              <p
                id="banner-2"
                style={{ position: "absolute", top: "1000px", left: "0px" }}
              ></p>
              <div className="carousel-slide">
                <div>
                  <h1
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-2"
                    data-aos-anchor-placement="top-bottom"
                  >
                    {/* <TextyAnim
                      className="content"
                      type="bottom"
                      split={getSplit}
                      delay={3000}
                      interval={30}
                    >
                      Users get superior results, tailored features with lower
                      cost.
                    </TextyAnim> */}
                    Users get superior results, tailored features with lower
                    cost.
                  </h1>
                  <p
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-2"
                    data-aos-anchor-placement="top-bottom"
                    style={{ textAlign: "left" }}
                  >
                    We help you personalize the solution to meet your exact
                    business needs.
                  </p>
                  <Link
                    style={{ float: "left" }}
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-2"
                    data-aos-anchor-placement="top-bottom"
                    className="banner-button"
                    type="button"
                    to="/contact"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 home-banner-video mob-hide">
              <img
                className="home-banner-video-img"
                src={HomeBanner2}
                alt=""
                style={{ width: "430px", height: "auto" }}
              />
            </div>
          </section>
        </div>
        <div className="container-fluid home-banner">
          <section className="container-xl row p-0 m-auto">
            <div className="col-md-6 home-banner-text">
              <p
                id="banner-3"
                style={{ position: "absolute", top: "0px", left: "0px" }}
              ></p>
              <div className="carousel-slide">
                <div>
                  <h1
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-3"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Deploy your AI powered product at various Platforms.
                  </h1>
                  <p
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-3"
                    data-aos-anchor-placement="top-bottom"
                    style={{ textAlign: "left" }}
                  >
                    Our team has expertise in cross platform development and
                    DevOps to deploy all cloud service providers.
                  </p>
                  <Link
                    style={{ float: "left" }}
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-3"
                    data-aos-anchor-placement="top-bottom"
                    className="banner-button"
                    type="button"
                    to="/contact"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 home-banner-video mob-hide">
              <img
                className="home-banner-video-img"
                src={HomeBanner3}
                alt=""
                style={{ width: "490px", height: "auto" }}
              />
            </div>
          </section>
        </div>
        <div className="container-fluid home-banner">
          <section className="container-xl row m-auto p-0">
            <div className="col-md-6 home-banner-text">
              <p
                id="banner-4"
                style={{ position: "absolute", top: "0px", left: "0px" }}
              ></p>
              <div className="carousel-slide">
                <div>
                  <h1
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-4"
                    data-aos-anchor-placement="top-bottom"
                  >
                    Expert training in AI & ML to build and strengthen your
                    business.
                  </h1>
                  <p
                    data-aos="flip-up"
                    data-aos-delay="50"
                    data-aos-anchor="#banner-4"
                    data-aos-anchor-placement="top-bottom"
                    style={{ textAlign: "left" }}
                  >
                    Your employees know your use cases, infrastructure, and
                    stack. We provide training to fill in the gaps, enabling
                    your organization to capitalize on the potential already
                    working under your roof.
                  </p>
                  <Link
                    style={{ float: "left" }}
                    data-aos="fade-up"
                    data-aos-delay="50"
                    className="banner-button"
                    data-aos-anchor="#banner-4"
                    data-aos-anchor-placement="top-bottom"
                    type="button"
                    to="/contact"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 home-banner-video mob-hide">
              <img
                className="home-banner-video-img"
                src={HomeBanner4}
                alt=""
                style={{ width: "490px", height: "auto" }}
              />
            </div>
          </section>
        </div>
      </Carousel>
      {/* <div className="slider-container home-banner"> */}
      {/* <Slider1
          autoplay={9000}
          id="sliderWrapper"
          className="slider-wrapper"
          previousButton={false}
          nextButton={false}
          onSlideChange={(e) => {
            console.log(e.slideIndex);
            document
              .getElementById(`Dot${e.slideIndex}`)
              .classList.add("active");
            if (e.slideIndex !== 0) {
              document
                .getElementById(`Dot${e.slideIndex - 1}`)
                .classList.remove("active");
            } else {
              document.getElementById(`Dot3`).classList.remove("active");
            }
          }}
        > */}
      {/* {content.map((slide, index) => { */}
      {/* return (
              <div
                className="slider-content container-fluid"
                key={index}
                id={`Slide${index + 1}`}
              > */}
      {/* <section className="container-xl row m-auto p-0">
                  <div className="col-md-6 m-0 home-banner-text"> */}
      {/* <p id="banner-1" style={{ ...animate }}></p> */}
      {/* <div className="carousel-slide">
                      <div className="inner">
                        <h1
                        // data-aos="flip-up"
                        // data-aos-delay="50"
                        // data-aos-anchor="#banner-1"
                        // data-aos-anchor-placement="top-bottom"
                        >
                          {slide.title}
                        </h1>
                        <p
                        // data-aos="flip-up"
                        // data-aos-anchor="#banner-1"
                        // data-aos-anchor-placement="top-bottom"
                        // style={{ textAlign: "left" }}
                        // data-aos-delay="50"
                        >
                          {slide.description}
                        </p>
                        <button
                          style={{ float: "left" }}
                          // data-aos="fade-up"
                          // data-aos-delay="50"
                          // data-aos-anchor="#banner-1"
                          // data-aos-anchor-placement="top-bottom"
                          className="banner-button"
                          type="button"
                          //to="/contact"
                          // onClick={()=>props.setPath()}
                        >
                          Learn More
                        </button>
                      </div>
                    </div>
                  </div> */}
      {/* <div
                    className={
                      index === 0
                        ? "col-6 m-0 mob-hide"
                        : "col-md-6 m-0 home-banner-video mob-hide"
                    }
                  >
                    <img
                      className="home-banner-video-img"
                      src={
                        index === 0
                          ? HomeBanner1
                          : index === 1
                          ? HomeBanner2
                          : index === 2
                          ? HomeBanner3
                          : HomeBanner4
                      }
                      alt=""
                      id={`home-banner-${index + 1}`}
                      style={
                        index === 0
                          ? {}
                          : index === 1
                          ? { width: "430px", height: "auto" }
                          : { width: "490px", height: "auto" }
                      }
                    />
                  </div>
                </section> */}
      {/* </div> */}
      {/* );
          })} */}
      {/* </Slider1> */}
      {/* <ul>
          <li
            id="Dot0"
            className=""
            // onClick={() => {
            //   setSlide(1);
            // }}
          ></li>
          <li
            id="Dot1"
            className=""
            // onClick={() => setSlide(2)}
          ></li>
          <li
            id="Dot2"
            className=""
            // onClick={() => setSlide(3)}
          ></li>
          <li
            id="Dot3"
            className=""
            // onClick={() => setSlide(4)}
          ></li>
        </ul>
      </div> */}
      <div className="container-company-logo">
        <h6 className="company-logo-title">
          Proud to have have served some of the world's leading organizations
        </h6>
        <Slider {...settingsLogo} className="company-logo-main-div">
          <img src={AmbujaCement} alt="" className="company-logo-image" />

          <img src={CEAT} alt="" className="company-logo-image" />

          <img src={Honda} alt="" className="company-logo-image" />

          <img src={LT} alt="" className="company-logo-image" />

          <img src={AmulDairy} alt="" className="company-logo-image" />

          <img src={Samsung} alt="" className="company-logo-image" />

          <img src={ApolloTyres} alt="" className="company-logo-image" />

          <img src={Bosch} alt="" className="company-logo-image" />

          <img src={Panasonic} alt="" className="company-logo-image" />

          <img src={Hitachi} alt="" className="company-logo-image" />
        </Slider>

        {/* <div className="company-logo-main-div">
          <img src={AmbujaCement} alt="" className="company-logo-image" />
          <img src={CEAT} alt="" className="company-logo-image" />
          <img src={Honda} alt="" className="company-logo-image" />
          <img src={LT} alt="" className="company-logo-image" />
          <img src={Hitachi} alt="" className="company-logo-image" />
          <img src={AmulDairy} alt="" className="company-logo-image" />
          <img src={Samsung} alt="" className="company-logo-image" />
          <img src={ApolloTyres} alt="" className="company-logo-image" />
          <img src={Bosch} alt="" className="company-logo-image" />
          <img src={Panasonic} alt="" className="company-logo-image" />
        </div> */}
      </div>
      <div className="container-fluid" id="home-products">
        <div className="container-xl">
          <div className="product-tab-list">
            <h1>
              <span style={{ fontWeight: "400" }}>Our</span> Products
            </h1>
            <p>
              Our API Provides access to human powered data for hundreds of use
              case.
            </p>
            <div className="row m-auto" style={{ maxWidth: "1150px" }}>
              <div
                className="col-3 home-product-list-icon"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <img
                  src={ProductImage}
                  alt=""
                  onClick={() => {
                    setProductTab(1);
                  }}
                />
                <span>Heu Image</span>
                {productTab === 1 ? <div className="arrow-down"></div> : null}
              </div>
              <div
                className="col-3 home-product-list-icon"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <img
                  src={ProductVideo}
                  alt=""
                  onClick={() => {
                    setProductTab(2);
                  }}
                />
                <span>Heu Video</span>
                {productTab === 2 ? <div className="arrow-down"></div> : null}
              </div>
              <div
                className="col-3 home-product-list-icon"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <img
                  src={ProductDocument}
                  alt=""
                  onClick={() => {
                    setProductTab(3);
                  }}
                />
                <span>Heu Document</span>
                {productTab === 3 ? <div className="arrow-down"></div> : null}
              </div>
              <div
                className="col-3 home-product-list-icon"
                data-aos="fade-up"
                data-aos-delay="350"
              >
                <img
                  src={ProductText}
                  alt=""
                  onClick={() => {
                    setProductTab(4);
                  }}
                />
                <span>Heu Text</span>
                {productTab === 4 ? <div className="arrow-down"></div> : null}
              </div>
            </div>
          </div>
          {productTab === 1 ? (
            <Image />
          ) : productTab === 2 ? (
            <Video />
          ) : productTab === 3 ? (
            <Document />
          ) : (
            <Text />
          )}
        </div>
      </div>
      <div
        data-aos="fade-up"
        class="bg-white container-fluid space-top-desktop"
      >
        <section class="ps-timeline-sec pt-5">
          <div class="container">
            <h1 className="text-center">
              <span style={{ fontWeight: "400" }}>Our</span> Approach
            </h1>

            <p class="text-center">
              Unlock the potential of AI with state-of-the-art products and
              tools to make AI real, scalable, and trustworthy
            </p>

            <div class="row mt-4 mb-4" style={{ display: "block" }}>
              <div class="col-md-12">
                <ol class="ps-timeline">
                  <li data-aos="fade-up" data-aos-delay="50">
                    <div class="img-handler-top">
                      <img
                        src={BusinessUnderstanding}
                        alt="business understanding"
                      />
                    </div>
                    <div class="ps-bot">
                      <p
                        className="timeline-label"
                        style={{ marginTop: "36px" }}
                      >
                        Business Understanding
                      </p>
                    </div>
                    <span class="ps-sp-top">01</span>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <div class="img-handler-top">
                      <img src={DataUnderstanding} alt="data understanding" />
                    </div>
                    <div class="ps-bot">
                      <p
                        className="timeline-label"
                        style={{ marginTop: "36px" }}
                      >
                        Data Understanding
                      </p>
                    </div>
                    <span class="ps-sp-top">02</span>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="150">
                    <div class="img-handler-top">
                      <img src={DataCleaning} alt="data cleaning" />
                    </div>
                    <div class="ps-bot">
                      <p
                        className="timeline-label"
                        style={{ marginTop: "22px" }}
                      >
                        Data Cleaning
                      </p>
                    </div>
                    <span class="ps-sp-top">03</span>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="200">
                    <div class="img-handler-top">
                      <img src={DataEnhancing} alt="data enhancing" />
                    </div>
                    <div class="ps-bot">
                      <p
                        className="timeline-label"
                        style={{ marginTop: "22px" }}
                      >
                        Data Enhancing
                      </p>
                    </div>
                    <span class="ps-sp-top">04</span>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="250">
                    <div class="img-handler-top">
                      <img
                        src={ModellingEvaluation}
                        alt="modelling evaluvation"
                      />
                    </div>
                    <div class="ps-bot">
                      <p
                        className="timeline-label"
                        style={{ marginTop: "20px" }}
                      >
                        Modelling & Evaluvation
                      </p>
                    </div>
                    <span class="ps-sp-top">05</span>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="300">
                    <div class="img-handler-top">
                      <img src={Deployment} alt="deployement" />
                    </div>
                    <div class="ps-bot">
                      <p
                        className="timeline-label"
                        style={{ marginTop: "30px" }}
                      >
                        Deployment
                      </p>
                    </div>
                    <span class="ps-sp-top">06</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container-fluid" id="home-tools">
        <div>
          <h1>
            <span style={{ fontWeight: "400" }}>Tools</span> We Use
          </h1>
          <p>
            Unlike traditional off-the-shelf AI solutions, our AI capabilities
            are customizable, combinable, and easily deployable.
          </p>
          <div className="container row m-auto tools-tabs">
            <Tabs defaultActiveKey="1" style={{ marginBottom: 32 }}>
              <TabPane
                tab="Programming Languages"
                key="1"
                className="tools-style"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <div className="row m-auto tools-row">
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src={Python} alt="Python" />
                  </div>
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src={Java} alt="Java" />
                  </div>
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src={JavaScript} alt="JavaScript" />
                  </div>
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src={Matlab} alt="Matlab" />
                  </div>
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <img src={Cpp} alt="Cpp" />
                  </div>
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <img src={Csharp} alt="Csharp" />
                  </div>
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <img src={Php} alt="Php" />
                  </div>
                  <div
                    className="col-md-3 col-6 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <img src={VBDotNet} alt="VBDotNet" />
                  </div>
                  <div
                    className="col-md-12 tools-box"
                    data-aos="fade-up"
                    data-aos-delay="250"
                  >
                    <img src={SQL} alt="SQL" />
                  </div>
                </div>
              </TabPane>
              <TabPane
                tab="Tools and Frameworks"
                key="2"
                className="tools-style"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <div className="row m-auto tools-row">
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Tensorflow} alt="Tensorflow" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Pytorch} alt="Pytorch" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Keras} alt="Keras" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={CNTK} alt="CNTK" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Theano} alt="Theano" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Node} alt="Node" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Angular} alt="Angular" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Django} alt="Django" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Flask} alt="Flask" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Spark} alt="Spark" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Hadoop} alt="Hadoop" />
                  </div>
                  <div className="col-md-3 col-6 tools-box" data-aos="fade-up">
                    <img src={Storm} alt="Storm" />
                  </div>
                </div>
              </TabPane>
              <TabPane
                tab="Databases"
                key="3"
                className="tools-style"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <div className="row m-auto tools-row">
                  <div className="col-md-3 col-6 tools-box">
                    <img src={PostgreSQL} alt="PostgreSQL" />
                  </div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={Firebase} alt="Firebase" />
                  </div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={DynamoDB} alt="DynamoDB" />
                  </div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={RedShift} alt="RedShift" />
                  </div>
                  <div className="col-md-3 mob-hide"></div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={MongoDB} alt="MongoDB" />
                  </div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={Oracle} alt="Oracle" />
                  </div>
                  <div className="col-md-3 mob-hide"></div>
                </div>
              </TabPane>
              <TabPane
                tab="DevOps"
                key=""
                className="tools-style"
                style={{ backgroundColor: "#f8f9fa" }}
              >
                <div className="row m-auto tools-row">
                  <div className="col-md-3 col-6 tools-box">
                    <img src={Azure} alt="Azure" />
                  </div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={AWS} alt="AWS" />
                  </div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={Google} alt="Google" />
                  </div>
                  <div className="col-md-3 col-6 tools-box">
                    <img src={Docker} alt="Docker" />
                  </div>
                  <div className="col-md-12 tools-box">
                    <img src={Gradle} alt="Gradle" />
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      <div
        class="container-fluid pt-4 pb-4 mb-4 mob-show p-0"
        style={{ backgroundColor: "#f5f8ff" }}
        data-aos="fade-up"
      >
        <div style={{ padding: "0px 15px" }}>
          <h1 class="text-center">
            <span style={{ fontWeight: "400" }}>Our</span> Clients
          </h1>
          <p class="text-center" style={{ fontSize: "13px" }}>
            There is only one boss, The Customer
          </p>
        </div>
        <img src={mapMobile} alt="" style={{ width: "100%", height: "auto" }} />
      </div>
      <div data-aos="fade-up" class="container-fluid mob-hide" id="map-section">
        <section class="pt-4 pb-4">
          <div class="container">
            <h1 class="text-center">
              <span style={{ fontWeight: "400" }}>Our</span> Clients
            </h1>
            <p class="text-center">There is only one boss, The Customer</p>
            <div class="maparea position-relative">
              <div
                class="row"
                style={{ position: "absolute", top: "30%", left: "20.5%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img src={locationIcon} alt="client logo" height="25px" />
                    <div
                      class="arrow"
                      style={{
                        left: "-0%",
                        top: "55%",
                        minHeight: "10px",
                        transform: "rotate(10deg)",
                      }}
                    ></div>
                    <div class="location-box-2 location-box">
                      <b>New York,</b>&nbsp; United States
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "44%", left: "69%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img
                      src={locationIcon}
                      alt="client logo"
                      height="25px"
                      onmouseover="mouseOverHandler(3)"
                      onmouseout="mouseOutHandler(3)"
                    />
                    <div
                      class="arrow"
                      style={{
                        left: "-50%",
                        top: "-20%",
                        minHeight: "30px",
                        transform: "rotate(90deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-3 location-box"
                      style={{ left: "-400%", top: "-30%" }}
                    >
                      <b>Mumbai,</b>&nbsp; India
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "45%", left: "70%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img
                      src={locationIcon}
                      alt="client logo"
                      height="25px"
                      onmouseover="mouseOverHandler(4)"
                      onmouseout="mouseOutHandler(4)"
                    />
                    <div
                      class="arrow"
                      style={{
                        left: "150%",
                        top: "-120%",
                        minHeight: "40px",
                        transform: "rotate(40deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-4 location-box"
                      style={{ top: "-350%", left: "485%" }}
                    >
                      <b>Pune,</b>&nbsp; India
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "47%", left: "71%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img src={locationIcon} alt="client logo" height="25px" />
                    <div
                      class="arrow"
                      style={{
                        left: "0%",
                        top: "100%",
                        minHeight: "40px",
                        transform: "rotate(20deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-5 location-box"
                      style={{ bottom: "-415%" }}
                    >
                      <b>Banglore,</b>&nbsp; India
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "45%", left: "71%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img src={locationIcon} alt="client logo" height="25px" />
                    <div
                      class="arrow"
                      style={{
                        left: "170%",
                        top: "-20%",
                        minHeight: "30px",
                        transform: "rotate(-70deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-7 location-box"
                      style={{ left: "640%", bottom: "-100%" }}
                    >
                      <b>Hyderabad,</b>&nbsp; India
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "35.5%", left: "70%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img src={locationIcon} alt="client logo" height="25px" />
                    <div
                      class="arrow"
                      style={{
                        left: "0%",
                        top: "-130%",
                        minHeight: "30px",
                        transform: "rotate(-10deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-6 location-box"
                      style={{ top: "-380%" }}
                    >
                      <b>Delhi,</b>&nbsp; India
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "20%", left: "44.4%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img src={locationIcon} alt="client logo" height="25px" />
                    <div
                      class="arrow"
                      style={{
                        left: "200%",
                        top: "-5%",
                        minHeight: "45px",
                        transform: "rotate(110deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-8 location-box"
                      style={{ left: "480%" }}
                    >
                      <b>London,</b>&nbsp; England
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "21%", left: "45%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img
                      src={locationIcon}
                      alt="client logo"
                      height="25px"
                      onmouseover="mouseOverHandler(9)"
                      onmouseout="mouseOutHandler(9)"
                    />
                    <div
                      class="arrow"
                      style={{
                        left: "-100%",
                        top: "-5%",
                        minHeight: "45px",
                        transform: "rotate(60deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-9 location-box"
                      style={{ left: "-350%" }}
                    >
                      <b>Cambridge,</b>&nbsp; England
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "17%", left: "43.5%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img
                      src={locationIcon}
                      alt="client logo"
                      height="25px"
                      onmouseover="mouseOverHandler(1)"
                      onmouseout="mouseOutHandler(1)"
                    />
                    <div
                      class="arrow"
                      style={{
                        left: "-35%",
                        top: "-30%",
                        minHeight: "22px",
                        transform: "rotate(90deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-1 location-box"
                      style={{ left: "-490%", bottom: "00%" }}
                    >
                      <b>Birmingham, </b>
                      England
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "32%", left: "7%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img
                      src={locationIcon}
                      alt="client logo"
                      height="25px"
                      onmouseover="mouseOverHandler(10)"
                      onmouseout="mouseOutHandler(10)"
                    />
                    <div
                      class="arrow"
                      style={{
                        top: "50%",
                        minHeight: "15px",
                        transform: "rotate(30deg)",
                      }}
                    ></div>
                    <div class="location-box-10 location-box">
                      <b>San Diego,</b>&nbsp; United States
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "12%", left: "50%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img
                      src={locationIcon}
                      alt="client logo"
                      height="25px"
                      onmouseover="mouseOverHandler(11)"
                      onmouseout="mouseOutHandler(11)"
                    />
                    <div
                      class="arrow"
                      style={{
                        top: "-200%",
                        minHeight: "50px",
                        transform: "rotate(-10deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-11 location-box"
                      style={{ top: "-300%" }}
                    >
                      <b>Moscow,</b>
                      <br />
                      Russia
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "27%", left: "22.5%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img src={locationIcon} alt="client logo" height="25px" />
                    <div
                      class="arrow"
                      style={{
                        top: "-200%",
                        minHeight: "50px",
                        transform: "rotate(-10deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-12 location-box"
                      style={{ top: "-400%" }}
                    >
                      <b>Boston,</b>&nbsp; United States
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                style={{ position: "absolute", top: "27%", left: "6.5%" }}
              >
                <div class="col-md-12 text-center">
                  <div data-aos="flip-left" data-aos-delay="100">
                    <span class="almidd"></span>
                    <img
                      src={locationIcon}
                      alt="client logo"
                      height="25px"
                      onmouseover="mouseOverHandler(13)"
                      onmouseout="mouseOutHandler(13)"
                    />
                    <div
                      class="arrow"
                      style={{
                        top: "-100%",
                        minHeight: "25px",
                        transform: "rotate(-20deg)",
                      }}
                    ></div>
                    <div
                      class="location-box-13 location-box"
                      style={{ top: "-440%" }}
                    >
                      <b>Seattle,</b>&nbsp; United States
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        id="testimonials"
        className="container-fluid"
        data-aos="fade-in"
        data-aos-delay="50"
      >
        <h1>
          <span style={{ fontWeight: "400" }}>Client</span> Testimonials
        </h1>
        <p>When we make our client smile that's real success.</p>
        <div className="container">
          <Slider {...sliderSettings}>{testimonialCards}</Slider>
        </div>
      </section>

      <div data-aos="fade-up">
        <ContactForm />
      </div>
    </div>
  );
};

export default Home;
