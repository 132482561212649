import React, { useEffect } from "react";
import "./Products.css";
import ScaleDocument from "../../assets/images/scale-doc.jpg";
import { Tabs } from "antd";
import DataExtraction from "./DocumentCodes/DataExtraction";
import ReactGA from "react-ga";

const { TabPane } = Tabs;

const Document = () => {
  const topData = [
    {
      title: "Data Extraction",
      text: "Accurately extract data from scanned documents and images.",
    },
  ];

  useEffect(() => {
    document.title = "Products";

    ReactGA.pageview(window.location);
  }, []);

  const topCards = topData.map((card) => {
    return (
      <div className="col-12 col-md-4 top-card-outer">
        <div className="top-card-inner">
          <h4>{card.title}</h4>
          <p>{card.text}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="document-banner"
        className="container-fluid products-banner"
      ></section>
      <section className="container-fluid products-content">
        <div className="container-xl">
          <h1>
            <img src={ScaleDocument} alt="" />
            <span style={{ fontWeight: "400" }}>Heu</span> Document
          </h1>
          <p>The comprehensive annotation platform for Document.</p>
          <div className="row products-row">
            <div className="col-md-4"></div>
            {topCards}
            <div className="col-md-4"></div>
          </div>
          <h1>
            <span style={{ fontWeight: "400" }}>How It</span> Works
          </h1>
          <p>
            Maximizing operational efficiency and reducing the cost of projects.
          </p>
          <div className="row products-row">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Data Extraction" key="1">
                <DataExtraction />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Document;