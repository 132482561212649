import React, { useEffect } from "react";
import "./Solutions.css";
import AOS from "aos";
import medicalImagingTop from "../../assets/images/solution_new/Medical_Imaging.jpg";
import medicalImagingContent from "../../assets/images/solutions/medical-imaging.jpg";
import DiseaseDiagnosis from "../../assets/images/solutions/diseasediagnosis.jpg";
import MedicalPrognosis from "../../assets/images/solutions/medical-prognosis.jpg";
import EmergencyTop from "../../assets/images/solutions/emergencyroom.jpg";
import EmergencyContent from "../../assets/images/solution_new/emmergency.jpg";
import MedicalFraudTop from "../../assets/images/solution_new/Medical_Fraud.jpg";
import MedicalFraudContent from "../../assets/images/solutions/fraud-detection.jpg";

import ReactGA from "react-ga";


const Healthcare = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 50,
    });

    document.title = "Solutions";

    ReactGA.pageview(window.location);
  }, []);

  const data = [
    {
      name: "Medical Imaging",
      text:
        "Our Medical imaging solution lets you Automate the detection of abnormalities in commonly-ordered imaging tests, such as chest x-rays, could lead to quicker decision-making and fewer diagnostic errors. It can also identify hard-to-see fractures, dislocations, or soft tissue injuries could allow surgeons and specialists to be more confident in their treatment choices. Allowing unbiased algorithms to review images in trauma patients help to ensure that all injuries are accounted for and receive the care required to secure a positive outcome.",
      topImage: medicalImagingTop,
      contentImage: medicalImagingContent,
    },
    {
      name: "Disease Diagnosis",
      text:
        "Disease diagnosis tool empowers healthcare organizations to build and deploy an AI-powered, compliant, conversational healthcare experience at scale. The service combines built-in medical intelligence with natural language capabilities, extensibility tools and compliance constructs, allowing healthcare organizations such as Providers, Payers, Pharma, HMOs, Telehealth to give people access to trusted and relevant healthcare services and information.",
      topImage: DiseaseDiagnosis,
      contentImage: DiseaseDiagnosis,
    },
    {
      name: "Medical Prognosis",
      text:
        "Our medical prognosis solution helps doctors predict diseases based on patient’s historical data. This further helps doctors take preventive measures to avoid occurring of the disease in the patient.",
      topImage: MedicalPrognosis,
      contentImage: MedicalPrognosis,
    },
    {
      name: "Emergency Room and Hospital Management",
      text:
        "It lessens time spent by staff and senior staff in physically counting and recording bed information as every staff in a hospital has required information regarding a particular patient on a particular bed, helps in offering better treatment and diagnosis procedure. With the availability of A&E information, our solution provides real-time information regarding hospital inventory and resource management.",
      topImage: EmergencyTop,
      contentImage: EmergencyContent,
    },
    {
      name: "Medical Claim Fraud Detection",
      text:
        "Investigating claims is time consuming and expensive with payers pursuing fraudulent cases for months or years after payments have been made. Fraudulent claims contribute to the increased cost of care, slow down valid claims, and lead to higher healthcare premiums for patients. Traditional manual review doesn’t scale across billions of claims per year and rules-based fraud detection systems are expensive and slow to adapt to new fraud techniques. Our solution lets you automate this process with better accuracy.",
      topImage: MedicalFraudTop,
      contentImage: MedicalFraudContent,
    },
  ];

  const topCards = [];

  const contentCards = [];

  data.forEach((item) => {
    topCards.push(
      <div class="project col-md-4 col-12" data-aos="fade" style={{height : "290px"}}>
        <div class="project__card">
          <div class="project__image">
            <img src={item.topImage} alt="" />
          </div>
          <div class="project__detail">
            <h2 class="project__title">{item.name}</h2>
          </div>
        </div>
        <div class="inilab">{item.name}</div>
      </div>
    );

    contentCards.push(
      <div className="col-12 col-md-12 row content-card">
        <div className="col-12 col-md-7 content-card-info">
          <h4>{item.name}</h4>
          <p>{item.text}</p>
        </div>
        <div className="col-12 col-md-5 content-card-image">
          <img src={item.contentImage} alt={item.name} />
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="healthcare-banner"
        className="container-fluid solutions-banner"
      ></section>
      <section className="container-fluid solutions-content">
        <div className="container-xl">
          <h1>
            <span style={{ fontWeight: "400" }}>Healthcare</span> Use Cases
          </h1>
          <p>
            Powering hospitals and healthcare industries with our highly
            accurate ai tools.
          </p>
          <div className="row solutions-row">{topCards}</div>
          <div className="row solutions-row">{contentCards}</div>
        </div>
      </section>
    </div>
  );
};

export default Healthcare;
