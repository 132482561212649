import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import PolygonBefore from "../../../assets/video/video_polygon_before.mp4";
import PolygonAfter from "../../../assets/video/video_polygon_after.mp4";

const BoundingBox = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  }

  const show = () => {
    setIsVisible(true);
  };


//   client.createVideoannotationTask(
//     {
//       callback_url: 'http://www.example.com/callback',
//       instruction: 'Draw a polygon around each label.',
//       attachment_type: 'video',
//       attachment: 'http://example.com/video.mp4',
//       objects_to_annotate: ['Couch', 'Coffee Table', 'Dining Table'],
//       with_labels: true,
//       annotation_grouping: true
//     },
//     (err, task) => {
//       // do something with task
//     }
//   );

  return (
    <div className="product-sub-tab">
      <h6>"Draw a polygon around each label."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createVideoannotationTask({
`}
        <code>{`callback_url:`}</code>
        {`  
    'http://www.example.com/callback',
`}
        <code>{`instruction:`}</code>
        {`
    'Draw a polygon around each label.',
`}
        <code>{`objects_to_annotate:`}</code>
        {`
    ['Couch', 'Coffee Table', 'Dining Table'],
`}
        <code>{`attachement_type:`}</code>
        {`  video,
`}
        <code>{`attachment:`}</code>
        {`  'http://example.com/video.mp4',
`}
        <code>{`with_label:`}</code>
        {`  true,
`}
        <code>{`annotation_grouping:`}</code>
        {`  true,
,(err, task) => {
      // do something with task
});
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <video src={PolygonAfter} autoPlay muted loop />
          ) : (
            <video src={PolygonBefore} autoPlay muted loop />
          )}
        </div>
      </div>
    </div>
  );
};

export default BoundingBox;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {` {
  "annotations": {
    "url": 
      "https://heu.ai/7adv7av7av8a",
  },
}
`}
        </pre>
      </div>
    </div>
  );
};
