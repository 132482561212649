import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import PointImage from "../../../assets/images/imgpts.jpg";

const Point = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Draw a point in each facial point and limb joint."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createAnnotationTask({
`}
            <code>{`callback_url:`}</code>
            {`  
  'http://www.example.com/callback',
`}
            <code>{`instruction:`}</code>
            {`
  'Draw a point in each facial point and limb joint.',
`}
            <code>{`params:`}</code>
            {`  {`}
            <code>{`
    with_labels`}</code>
            :{`  true,`}
            <code>{`
    objects_to_annotate`}</code>
            :
            {`  ['limb joint', 'facial point'],`}
            <code>{`
    attachment_type`}</code>
            :
            {`  'image',`}
            <code>{`
    attachment`}</code>
            :
            {`  'https://example.com/image'
},(err, task) => {
  // do something with task
});
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={PointImage} alt="" />
          ) : (
            <img src={PointImage} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Point;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
"response": {
  "annotations": [
  {
    "label": "facial point",
    "left": 477.457457,
    "top": 457457.4588,
  },
  {
    "label": "facial point",
    "left": 488.3463737,
    "top": 35737.34783838,
  },
  ...
  {
    "label": "limb joint",
    "left": 38838.35838,
    "top": 3838.383838,
    "geometry": "point",
  },
  ...
  ]
  },
  "task_id": "5sdfb4yefxnxu",
  "task": { ... }
}                         
`}
        </pre>
      </div>
    </div>
  );
};
