import React, { useEffect } from "react";
import "./Products.css";
import ScaleImage from "../../assets/images/scale-img.jpg";
import { Tabs } from "antd";
import BoundingBox from "./ImageCodes/BoundingBox";
import Classification from "./ImageCodes/Classification";
import Cuboid from "./ImageCodes/Cuboid";
import LinesSplines from "./ImageCodes/LinesSplines";
import Point from "./ImageCodes/Point";
import Segmentation from "./ImageCodes/Segmentation";
import Transcription from "./ImageCodes/Transcription";

import ReactGA from "react-ga";

const { TabPane } = Tabs;

const Image = () => {
  const topData = [
    {
      title: "Bounding Box",
      text:
        "The annotators draw a bounding box over an object and label them with an object class.",
    },
    {
      title: "Cuboid",
      text:
        "Cuboid annotation is drawing a cube over an object to get 3D perspectives on height, width and depth.",
    },
    {
      title: "Segmentation",
      text:
        "Semantic segmentation is the process of associating every single pixel in an entire image with a tag.",
    },
    {
      title: "Classification",
      text:
        "Applies a classification algorithm to identify shared characteristics of certain classes.",
    },
    {
      title: "Lines and Splines",
      text:
        "The annotators draw lines along the boundaries you require your machine to learn.",
    },
    {
      title: "Transcription",
      text:
        "It processes and digitizes text that is pictured in an image, such as a photograph of a receipt or handwriting.",
    },
  ];

  useEffect(() => {
    document.title = "Products";

    ReactGA.pageview(window.location);
  }, []);

  const topCards = topData.map((card) => {
    return (
      <div className="col-12 col-md-4 top-card-outer">
        <div className="top-card-inner">
          <h4>{card.title}</h4>
          <p>{card.text}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="image-banner"
        className="container-fluid products-banner"
      ></section>
      <section className="container-fluid products-content">
        <div className="container-xl">
          <h1>
            <img src={ScaleImage} alt="" />
            <span style={{ fontWeight: "400" }}>Heu</span> Image
          </h1>
          <p>The comprehensive annotation platform for images.</p>
          <div className="row products-row">{topCards}</div>
          <h1>
            <span style={{ fontWeight: "400" }}>How It</span> Works
          </h1>
          <p>
            Maximizing operational efficiency and reducing the cost of projects.
          </p>
          <div className="row products-row">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Bounding Box" key="1">
                <BoundingBox />
              </TabPane>
              <TabPane tab="Classification" key="2">
                <Classification />
              </TabPane>
              <TabPane tab="Cuboid" key="3">
                <Cuboid />
              </TabPane>
              <TabPane tab="Lines and Splines" key="4">
                <LinesSplines />
              </TabPane>
              <TabPane tab="Point" key="5">
                <Point />
              </TabPane>
              <TabPane tab="Segmentation" key="6">
                <Segmentation />
              </TabPane>
              <TabPane tab="Transcription" key="7">
                <Transcription />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Image;
