import React, { useState } from "react";
import { Button, Popover, Radio } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const Classification = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"What is the sentiment of this review?"</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.*createCategorizationTask*({
`}
            <code>{`callback_url`}</code>:
            {`'http://www.example.com/callback',
`}
            <code>{`instruction`}</code>:
            {`'What is the sentiment of this review?',
`}
            <code>{`params`}</code>:
            {`{
`}
            <code>{`    t_type`}</code>:
            {`'image',
`}
            <code>{`    attachment`}</code>:
            {`'example.png',
`}
            <code>{`    taxonomies`}</code>:
            {` {
`}
            <code>{`        sentiment`}</code>:
            {`{
`}
            <code>{`            type`}</code>:
            {`'category',
`}
            <code>{`            description`}</code>:
            {`
              'What is the sentiment of this review?',
`}
            <code>{`            choices`}</code>:
            {`['Negative', 'Neutral', 'Positive'],
        }
      }
    },
  }, (err, task) => {
      // do something with task
  });
  
  
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          <h6 style={{ textAlign: "left", lineHeight: "2" }}>
            "I loved the idea, We can immediately start implementing this
            feature. Lets meet on the coming sunday."
          </h6>
          {isVisible ? (
            <div className="radio-list">
              <Radio.Group value={2}>
                <Radio value={1}>Neutral</Radio>
                <Radio value={2}>Positive</Radio>
                <Radio value={3}>Negative</Radio>
              </Radio.Group>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Classification;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "response": {
    "taxonomies": {
      "sentiment": ['Positive']
    }
  }
  "task_id": 
    '5795c68afce306120434be64',
  "task": { ... }
}
`}
        </pre>
      </div>
    </div>
  );
};
