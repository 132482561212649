import React, { useState, useEffect } from "react";
import "./App.css";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { debounce } from "lodash";
import AOS from "aos";
import navMenu from "./assets/images/phone_menu_icon.svg";
import logoMobile from "./assets/images/logo.png";
import Logo3 from "./assets/images/lp.png";
import Home from "./Components/Home";
import Contact from "./Components/Contact";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Career from "./Components/Career";
import TermsOfUse from "./Components/TermsOfUse";
import About from "./Components/About";

//Trainings
import Python from "./Components/Training/Python";
import DataScience from "./Components/Training/DataScience";
import MachineLearning from "./Components/Training/MachineLearning";
import ComputerVision from "./Components/Training/ComputerVision";
import NLP from "./Components/Training/NLP";
import Robotics from "./Components/Training/Robotics";

//Solutions
import Healthcare from "./Components/Solutions/Healthcare";
import Retail from "./Components/Solutions/Retail";
import Mobility from "./Components/Solutions/Mobility";
import Drone from "./Components/Solutions/Drone";
import Face from "./Components/Solutions/Face";
import SolutionRobotics from "./Components/Solutions/Robotics";

//Products
import Image from "./Components/Produts/Image";
import Video from "./Components/Produts/Video";
import Document from "./Components/Produts/Document";
import Text from "./Components/Produts/Text";

import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

import ReactGA from "react-ga";

function App() {
  const [flag, setFlag] = useState(false);
  const [path, setPath] = useState("");

  ReactGA.initialize("UA-191789032-1");

  useEffect(() => {
    window.addEventListener("scroll", resize);
    return () => {
      window.removeEventListener("scroll", resize);
    };
  });

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 200,
      easing: "ease-in-sine",
      delay: 50,
    });
  }, []);

  const setPathContact = () => {
    setPath("contact");
  };

  const resize = debounce(() => {
    if (window.scrollY > 80) setFlag(true);
    else setFlag(false);
  }, 20);

  const productsMenu = (
    <Menu className>
      <Menu.Item>
        <Link
          to="/products/image"
          onClick={() => {
            setPath("product");
          }}
        >
          Image
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/products/video"
          onClick={() => {
            setPath("product");
          }}
        >
          Video
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/products/document"
          onClick={() => {
            setPath("product");
          }}
        >
          Documents
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/products/text"
          onClick={() => {
            setPath("product");
          }}
        >
          Text
        </Link>
      </Menu.Item>
    </Menu>
  );

  const solutionsMenu = (
    <Menu className>
      <Menu.Item>
        <Link
          to="/solutions/healthcare"
          onClick={() => {
            setPath("solutions");
          }}
        >
          Healthcare
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setPath("solution");
          }}
          to="/solutions/robotics"
        >
          Robotics
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/solutions/retail"
          onClick={() => {
            setPath("solutions");
          }}
        >
          Retail
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/solutions/mobility"
          onClick={() => {
            setPath("solutions");
          }}
        >
          Mobility
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/solutions/drone"
          onClick={() => {
            setPath("solutions");
          }}
        >
          Drone
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/solutions/face"
          onClick={() => {
            setPath("solutions");
          }}
        >
          Face
        </Link>
      </Menu.Item>
    </Menu>
  );

  const trainingMenu = (
    <Menu className>
      <Menu.Item>
        <Link
          to="/training/python"
          onClick={() => {
            setPath("training");
          }}
        >
          Python
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/training/data-science"
          onClick={() => {
            setPath("training");
          }}
        >
          Data Science
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/training/machine-learning"
          onClick={() => {
            setPath("training");
          }}
        >
          Machine Learning
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/training/computer-vision"
          onClick={() => {
            setPath("training");
          }}
        >
          Computer Vision
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/training/natural-language-processing"
          onClick={() => {
            setPath("training");
          }}
        >
          Natural Language Processing
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/training/robotics"
          onClick={() => {
            setPath("training");
          }}
        >
          Robotics
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      {false ? (
        <div className="loader">loading</div>
      ) : (
        <Router>
          <div>
            <header>
              <nav
                className="navbar navbar-light  navbar-expand-md fixed-top"
                id={flag ? "nav-bar" : "nav-bar-init"}
              >
                <div className="container-xl m-auto" style={{ zIndex: "99" }}>
                  <Link
                    to="/"
                    onClick={() => {
                      setPath("home");
                    }}
                  >
                    {window.innerWidth > 900 &&
                    (path === "home" || path === "") ? (
                      <img
                        // src={flag ? logoMobile : Logo3}
                        src={Logo3}
                        alt="Heu AI"
                        id="navbar_logo"
                        data-aos="fade"
                        className={
                          !flag && path === "home"
                            ? "logo-expanded"
                            : "logo-contracted"
                        }
                      />
                    ) : (
                      <img
                        src={logoMobile}
                        alt="Heu AI"
                        id="navbar_logo"
                        data-aos="fade"
                        style={{
                          height: "auto",
                          width: flag ? "165px" : "175px",
                        }}
                      />
                    )}
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    data-aos="fade"
                    style={{
                      marginTop: "8px",
                      marginRight: "12px",
                      marginBottom: "6px",
                    }}
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item" data-aos="fade">
                        <Link
                          to="/"
                          onClick={() => {
                            setPath("home");
                          }}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item dropdown" data-aos="fade">
                        <Dropdown overlay={productsMenu}>
                          <Link
                            className="ant-dropdown-link"
                            //onClick={(e) => e.preventDefault()}
                            to="/products"
                            onClick={() => {
                              setPath("products");
                            }}
                          >
                            Products <DownOutlined />
                          </Link>
                        </Dropdown>
                      </li>
                      <li className="nav-item dropdown" data-aos="fade">
                        <Dropdown overlay={solutionsMenu}>
                          <Link
                            className="ant-dropdown-link"
                            //onClick={(e) => e.preventDefault()}
                            to="/solutions"
                            onClick={() => {
                              setPath("solutions");
                            }}
                          >
                            Solutions <DownOutlined />
                          </Link>
                        </Dropdown>
                      </li>
                      <li className="nav-item dropdown" data-aos="fade">
                        <Dropdown overlay={trainingMenu}>
                          <Link
                            className="ant-dropdown-link"
                            //onClick={(e) => e.preventDefault()}
                            to="/training"
                            onClick={() => {
                              setPath("training");
                            }}
                          >
                            Training <DownOutlined />
                          </Link>
                        </Dropdown>
                      </li>
                      <li className="nav-item" data-aos="fade">
                        <Link
                          to="/career"
                          onClick={() => {
                            setPath("career");
                          }}
                        >
                          Career
                        </Link>
                      </li>
                      <li className="nav-item" data-aos="fade">
                        <Link
                          style={{ color: "red" }}
                          to="/contact"
                          onClick={() => {
                            setPath("contact");
                          }}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </header>

            <Switch>
              <Route
                path="/home"
                exact
                component={() => <Home setPath={setPathContact} />}
              />
              <Route path="/" exact component={Home} />
              <Route path="/products" exact component={Image}></Route>
              <Route
                path="/solutions"
                exact
                component={SolutionRobotics}
              ></Route>
              <Route path="/training" exact component={Python}></Route>
              <Route path="/contact" exact component={Contact} />
              <Route path="/about" exact component={About} />

              <Route
                path="/facebook"
                exact
                component={() => {
                  window.location.href = "https://www.facebook.com/HeuTech";
                  return null;
                }}
              />
              <Route
                path="/linkedin"
                exact
                component={() => {
                  window.location.href =
                    "https://www.linkedin.com/company/heu-ai/";
                  return null;
                }}
              />
              <Route
                path="/instagram"
                exact
                component={() => {
                  window.location.href = "https://www.instagram.com/heu.ai/";
                  return null;
                }}
              />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/terms-of-use" exact component={TermsOfUse} />
              <Route path="/career" exact component={Career} />
              <Route path="/training/python" exact component={Python} />
              <Route
                path="/training/data-science"
                exact
                component={DataScience}
              />
              <Route
                path="/training/machine-learning"
                exact
                component={MachineLearning}
              />
              <Route
                path="/training/computer-vision"
                exact
                component={ComputerVision}
              />
              <Route
                path="/training/natural-language-processing"
                exact
                component={NLP}
              />
              <Route path="/training/robotics" exact component={Robotics} />
              <Route
                path="/solutions/healthcare"
                exact
                component={Healthcare}
              />
              <Route path="/solutions/retail" exact component={Retail} />
              <Route path="/solutions/mobility" exact component={Mobility} />
              <Route path="/solutions/drone" exact component={Drone} />
              <Route path="/solutions/face" exact component={Face} />
              <Route
                path="/solutions/robotics"
                exact
                component={SolutionRobotics}
              />
              <Route path="/products/image" exact component={Image} />
              <Route path="/products/video" exact component={Video} />
              <Route path="/products/document" exact component={Document} />
              <Route path="/products/text" exact component={Text} />
            </Switch>

            <footer className="container-fluid">
              <div className="container row m-auto">
                <div className="col-md-1 col-6 mob-hide"></div>
                <div className="col-md-2 col-6">
                  <h6>Products</h6>
                  <ul className="footer-list">
                    <li>
                      <Link
                        onClick={() => {
                          setPath("product");
                        }}
                        to="/products/image"
                      >
                        Heu Image
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("product");
                        }}
                        to="/products/video"
                      >
                        Heu Video
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("product");
                        }}
                        to="/products/document"
                      >
                        Heu Document
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("product");
                        }}
                        to="/products/text"
                      >
                        Heu Text
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 col-6">
                  <h6>Company</h6>
                  <ul className="footer-list">
                    <li>
                      <Link
                        onClick={() => {
                          setPath("about");
                        }}
                        to="/about"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("career");
                        }}
                        to="/career"
                      >
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("contact");
                        }}
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 col-6">
                  <h6>Solutions</h6>
                  <ul className="footer-list">
                    <li>
                      <Link
                        onClick={() => {
                          setPath("solution");
                        }}
                        to="/solutions/healthcare"
                      >
                        Healthcare
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("solution");
                        }}
                        to="/solutions/robotics"
                      >
                        Robotics
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("solution");
                        }}
                        to="/solutions/retail"
                      >
                        Retail
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("solution");
                        }}
                        to="/solutions/mobility"
                      >
                        Mobility
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("solution");
                        }}
                        to="/solutions/drone"
                      >
                        Drone
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setPath("solution");
                        }}
                        to="/solutions/face"
                      >
                        Face
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 col-6">
                  <h6>Find Us</h6>
                  <ul className="footer-list">
                    <li>
                      <Link to="/instagram">Instagram</Link>
                    </li>
                    <li>
                      <Link to="/linkedin">LinkedIn</Link>
                    </li>
                    <li>
                      <Link to="/facebook">Facebook</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 col-6">
                  <h6>Contact</h6>
                  <ul className="footer-list">
                    <li>
                      <a href={"mailto:info@heu.ai"}>info@heu.ai</a>
                    </li>
                    <li>
                      <a href={"mailto:career@heu.ai"}>career@heu.ai</a>
                    </li>
                    <li>
                      <a href={"mailto:director@heu.ai"}>director@heu.ai</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-1 col-6 mob-hide"></div>
              </div>
              <hr />
              <div className="row m-auto" style={{ maxWidth: "1400px" }}>
                <div className="col-md-3 col-12 footer-tag">
                  <span>Copyright © 2023 heu.ai. All rights reserved.</span>
                </div>
                <div className="col-md-6 col-12 footer-tag"></div>
                <div
                  className="col-md-3 col-12 footer-tag"
                  style={{ paddingRight: "0px", paddingLeft: "7%" }}
                >
                  <span>
                    <Link
                      to="/terms-of-use"
                      onClick={() => {
                        setPath("doc");
                      }}
                    >
                      Terms of Use
                    </Link>{" "}
                    &&nbsp;
                    <Link
                      onClick={() => {
                        setPath("doc");
                      }}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                  </span>
                </div>
              </div>
            </footer>
          </div>
        </Router>
      )}
    </React.Fragment>
  );
}

export default App;
