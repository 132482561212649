import React, { useEffect } from "react";
import "./Training.css";
import { Tag, Collapse } from "antd";
import {
  ClockCircleOutlined,
  FileDoneOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TrainingForm from "./TrainingForm";
import LearnFromUs from "./LearnFromUs";

import ReactGA from "react-ga";

const { Panel } = Collapse;

const tagStyle = {
  color: "black",
  padding: "6px 8px",
  borderRadius: "6px",
  fontSize: "14px",
  margin: "6px",
};

const ComputerVision = () => {

  useEffect(() => {
    document.title = "Training";

    ReactGA.pageview(window.location);
  }, []);

  return (
    <div className="page-home">
      <section
        className="training-banner container-fluid"
        id="computer-vision-banner"
      >
        <div className="container-xl row m-auto">
          <div className="col-md-6 training-info">
            <h1>Course 4 : Computer Vision</h1>
            <p>
              Computer vision is the next big thing in machine learning and
              artificial intelligence. Giving our machines the ability not only
              to analyze through data input but to "see" what it is they're
              investigating, we open up our data to more kinds of inputs than
              traditional text. It provides an analysis of image data, including
              object recognition and image classification, allowing us to
              analyze our unstructured data more closely. Computer vision has
              applications in a wide range of areas from self-driving cars to
              smartphones. Deep learning models are making computer vision tasks
              more accurate, and soon, our computers will be able to "see" much
              the same way we do.
            </p>
            <div className="training-tags">
              <Tag
                style={tagStyle}
                icon={
                  <ClockCircleOutlined
                    style={{ color: "#f8b63a", fontSize: "22px" }}
                  />
                }
              >
                20 Hours
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <ProjectOutlined
                    style={{ color: "green", fontSize: "22px" }}
                  />
                }
              >
                20 Exercises
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <FileDoneOutlined
                    style={{ color: "blue", fontSize: "22px" }}
                  />
                }
              >
                4 Assignments
              </Tag>
            </div>
          </div>
          <div className="training-form-div col-md-6">
            <TrainingForm />
          </div>
        </div>
      </section>
      <section className="course-content container-fluid">
        <div className="container-xl m-auto">
          <h5>Course Curriculum</h5>
          <Collapse
            style={{ backgroundColor: "#f7f7f7" }}
            expandIcon={() => {
              return null;
            }}
          >
            <Panel header="Module 1 : OpenCV" key="1">
              <ul>
                <li>Images & Pixels</li>
                <li>Grey Scaling</li>
                <li>Colour Spaces</li>
                <li>Histogram Representation</li>
                <li>Resizing & Scaling</li>
                <li>Blurring, Sharpening & Thresholding</li>
                <li>Edge Detection</li>
                <li>Contours</li>
                <li>Shape Identification</li>
              </ul>
            </Panel>

            <Panel header="Module 2 : Deep Learning" key="2">
              <ul>
                <li>Deep Neural Networks</li>
              </ul>
            </Panel>

            <Panel header="Module 3 : Convolutional Neural Networks" key="3">
              <ul>
                <li>Convolution Operation</li>
                <li>Kernels</li>
                <li>Layers</li>
                <li>Pooling</li>
                <li>CNN in Keras</li>
              </ul>
            </Panel>

            <Panel header="Module 4 : Architectures in Keras" key="4">
              <ul>
                <li>VGG-16</li>
                <li>VGG-19</li>
                <li>InceptionV3</li>
                <li>ResNet-50</li>
                <li>YOLO</li>
              </ul>
            </Panel>

            <Panel header="Module 5 : Training on YOLO" key="5">
              <ul>
                <li>Validation Loss</li>
                <li>Accuracy</li>
                <li>Understanding the Output</li>
                <li>Saving the Model</li>
              </ul>
            </Panel>

            <Panel header="Module 6 : Classification Algorithms" key="6">
              <ul>
                <li>Logistic Regression</li>
                <li>K-Nearest Neighbours</li>
                <li>Support Vector Machine (SVM)</li>
                <li>Naïve Bayes</li>
                <li>Decision Tree Classification</li>
                <li>Random Forest Classification</li>
              </ul>
            </Panel>

            <Panel header="Module 7 : Testing AI Model" key="7">
              <ul>
                <li>Confusion Matrix</li>
                <li>Plotting Model Output</li>
                <li>ROC Curve</li>
              </ul>
            </Panel>

          </Collapse>
        </div>
      </section>
      <LearnFromUs />
    </div>
  );
};

export default ComputerVision;
