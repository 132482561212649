import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import LinesSplinesBefore from "../../../assets/images/lines-splines.jpg";
import LinesSplinesAfter from "../../../assets/images/lines-splines-run.jpg";

const LinesSplines = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Annotate all the lines and circles of the field."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createLineannotationTask({
`}
            <code>{`instruction:`}</code>
            {`
    'Annotate all the lines and circles of the field.',
`}
      <code>{`objects_to_annotate:`}</code>
            {` 
  ['edges', 'borders'],`}
  <code>{`
splines: `}</code>{`false,`}
  <code>{`
with_labels: `}</code>{`true,`}
{`
}, (err, task) => {
    // do something with task
});
            `}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={LinesSplinesAfter} alt="" />
          ) : (
            <img src={LinesSplinesBefore} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default LinesSplines;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "annotations": [
    {
      "vertices": [
        {
          "x": 673,
          "y": 477
        },
        {
          "x": 383,
          "y": 277
        },
        {
          "x": 494,
          "y": 488
        },
        {
          "x": 097,
          "y": 557
        },
        {
          "x": 457,
          "y": 266
        },
        {
          "x": 488,
          "y": 235
        },
        {
          "x": 458,
          "y": 369
        },
        {
          "x": 465,
          "y": 473
        },
        {
          "x": 457,
          "y": 776
        },
        {
          "x": 457,
          "y": 255
        },
        {
          "x": 969,
          "y": 375
        },
        {
          "x": 477,
          "y": 373
        },
        {
          "x": 588,
          "y": 598
        },
        {
          "x": 356,
          "y": 754
        },
        {
          "x": 976,
          "y": 544
        },
        {
          "x": 654,
          "y": 755
        },
        {
          "x": 578,
          "y": 535
        },
        {
          "x": 578,
          "y": 675
        }
      ],
      "label": "circles",
      "complete": true
    },
    {
      "vertices": [
        {
          "x": 734,
          "y": 457
        },
        {
          "x": 377,
          "y": 965
        },
        {
          "x": 541,
          "y": 835
        },
        {
          "x": 564,
          "y": 345
        },
        {
          "x": 564,
          "y": 746
        },
        {
          "x": 291,
          "y": 340
        },
        {
          "x": 287,
          "y": 346
        },
        {
          "x": 436,
          "y": 647
        },
        {
          "x": 235,
          "y": 853
        },
        {
          "x": 654,
          "y": 874
        }
      ],
      "label": "circles",
      "complete": true
    },
    ...
  ]
}    
`}
        </pre>
      </div>
    </div>
  );
};
