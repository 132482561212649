import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import CuboidBefore from "../../../assets/images/imgcub.jpg";
import CuboidAfter from "../../../assets/images/imgcubrun.jpg";

const Cuboid = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Draw a cuboid around each building or tower."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`
client.createCuboidannotationTask({
`}
            <code>{`callback_url:`}</code>
            {`  
  'https://example.com/image,
`}
            <code>{`instruction:`}</code>
            {`
  'Draw a cuboid around each building or tower.,
`}
            <code>{`attachment_type:`}</code>
            {`  'image',
`}
            <code>{`objects_to_annotate:`}</code>
            {` 
  '['building', 'tower']', 
}, (err, task) => {
    // do something with task
});
            `}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={CuboidAfter} alt="" />
          ) : (
            <img src={CuboidBefore} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Cuboid;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "annotations": [
    {
      "label": "building",
      "vertices": [
        {
          "type": "vertex",
          "x": 582.75,
          "y": 274.55,
          "description": "face-topleft"
        },
        {
          "type": "vertex",
          "x": 422.5,
          "y": 459.5,
          "description": "face-bottomleft"
        },
        {
          "type": "vertex",
          "x": 766.34,
          "y": 454.75,
          "description": "face-topright"
        }
      ],
      "edges": [
        {
          "description": "face-left",
          "y2": 230,
          "x2": 561,
          "y1": 456,
          "x1": 987,
          "type": "edge"
        },
        {
          "description": "face-top",
          "y2": 456,
          "x2": 234,
          "y1": 654,
          "x1": 789,
          "type": "edge"
        },
        {
          "description": "face-bottom",
          "y2": 453,
          "x2": 673,
          "y1": 987,
          "x1": 765,
          "type": "edge"
        }
      ]
    }
  ]
}             
`}
        </pre>
      </div>
    </div>
  );
};
