import React, { useEffect } from "react";
import "./Training.css";
import { Tag, Collapse } from "antd";
import {
  ClockCircleOutlined,
  FileDoneOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TrainingForm from "./TrainingForm";
import LearnFromUs from "./LearnFromUs";

import ReactGA from "react-ga";

const { Panel } = Collapse;

const tagStyle = {
  color: "black",
  padding: "6px 8px",
  borderRadius: "6px",
  fontSize: "14px",
  margin: "6px",
};

const Python = () => {

  useEffect(() => {
    document.title = "Training";

    ReactGA.pageview(window.location);
  }, []);


  return (
    <div className="page-home">
      <section className="training-banner container-fluid" id="python-banner">
        <div className="container-xl row m-auto">
          <div className="col-md-6 training-info">
            <h1>Course 1 : Python</h1>
            <p>
              Python is one of the most widely-used programming languages among
              data scientists. This course will show you the technical
              advantages it has over other programming languages. You will start
              working with its modules for scientific computing, and you will
              begin to understand why these functionalities make Python the
              preferred choice in finance, econometrics, economics, data
              science, and machine learning.
            </p>
            <div className="training-tags">
              <Tag
                style={tagStyle}
                icon={
                  <ClockCircleOutlined
                    style={{ color: "#f8b63a", fontSize: "22px" }}
                  />
                }
              >
                30 Hours
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <ProjectOutlined
                    style={{ color: "green", fontSize: "22px" }}
                  />
                }
              >
                30 Exercises
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <FileDoneOutlined
                    style={{ color: "blue", fontSize: "22px" }}
                  />
                }
              >
                4 Assignments
              </Tag>
            </div>
          </div>
          <div className="training-form-div col-md-6">
            <TrainingForm />
          </div>
        </div>
      </section>
      <section className="course-content container-fluid">
        <div className="container-xl m-auto">
          <h5>Course Curriculum</h5>
          <Collapse
            style={{ backgroundColor: "#f7f7f7" }}
            expandIcon={() => {
              return null;
            }}
          >
            <Panel header="Module 0 : Python Setup" key="1">
              <ul>
                <li>Installation of Python</li>
                <li>Command line Basics</li>
                <li>Modes of Executing Python code</li>
                <li>Running first Python Code</li>
              </ul>
            </Panel>

            <Panel header="Module 1 : Introduction to Python" key="2">
              <ul>
                <li>Introduction to Python Datatypes</li>
                <li>Python Numbers</li>
                <li>Introduction to strings</li>
                <li>Indexing and slicing with strings</li>
                <li>Print Formatting with Strings</li>
                <li>Booleans</li>
              </ul>
            </Panel>

            <Panel header="Module 2 : Datatypes" key="3">
              <ul>
                <li>Lists</li>
                <li>Dictionary</li>
                <li>Tuples</li>
                <li>Sets</li>
                <li>Named Tuple</li>
              </ul>
            </Panel>

            <Panel header="Module 3 : Input / Output – File Handling" key="4">
              <ul>
                <li>Reading and Writing Text Files</li>
                <li>With Statement</li>
                <li>File Modes</li>
              </ul>
            </Panel>

            <Panel header="Module 4: Conditional Statements & Loops" key="5">
              <ul>
                <li>If - Elif and Else Statement</li>
                <li>For Loop</li>
                <li>While Loop</li>
                <li>Nested Loops</li>
                <li>Break and Continue Statements</li>
              </ul>
            </Panel>

            <Panel header="Module 5 : Functions" key="6">
              <ul>
                <li>Introduction to Defining Functions</li>
                <li>Types of Functions</li>
                <li>Parameters and Return</li>
                <li>Introduction to *args</li>
              </ul>
            </Panel>

            <Panel header="Module 6 : Classes" key="7">
              <ul>
                <li>Introduction to OOPS concepts</li>
                <li>Abstraction and Encapsulation</li>
                <li>Inheritance and Polymorphism</li>
                <li>Attributes and class Keyword</li>
                <li>Objects and Methods</li>
              </ul>
            </Panel>

            <Panel header="Module 7 : Modules and PIP" key="8">
              <ul>
                <li>Pip commands and Pypi</li>
                <li>Modules and Packages</li>
                <li>__name__ and __main__</li>
              </ul>
            </Panel>

            <Panel header="Module 8 : Exception Handling" key="9">
              <ul>
                <li>Errors – Types of Errors</li>
                <li>Exceptions</li>
                <li>Exceptions Handling</li>
                <li>Try, Except, Else and Finally</li>
                <li>Raising Exception</li>
                <li>Creating User Defined Exception</li>
              </ul>
            </Panel>

            <Panel
              header="Module 9 : Iterators, Generators and Decorators"
              key="10"
            >
              <ul>
                <li>Iterators vs Iterables</li>
                <li>Own version of for loops</li>
                <li>Custom Iterator</li>
                <li>Generators</li>
                <li>Decorator</li>
              </ul>
            </Panel>

            <Panel
              header="Module 10 : Lambda & and Built-In Functions"
              key="11"
            >
              <ul>
                <li>Lambdas</li>
                <li>Map</li>
                <li>Filter</li>
                <li>Any and All</li>
                <li>Sorted</li>
                <li>Min and Max</li>
                <li>Reversed</li>
                <li>Len</li>
                <li>Zip</li>
                <li>Enumerate</li>
              </ul>
            </Panel>

            <Panel
              header="Module 11 : Working with CSV, Pickle and JSON"
              key="12"
            >
              <ul>
                <li>Working with CSV files</li>
                <li>Pickling</li>
                <li>Reading and Writing Json files</li>
              </ul>
            </Panel>

            <Panel header="Module 12 : Regular Expressions" key="13">
              <ul>
                <li>Introduction to Regular Expressions</li>
                <li>Writing basic Regex</li>
                <li>Quantifiers</li>
                <li>Character Classes and Sets</li>
                <li>Anchors and Boundaries</li>
                <li>Logical or and Capture Groups</li>
                <li>RE Module</li>
              </ul>
            </Panel>

            <Panel header="Module 13 : Coding Standards" key="14">
              <ul>
                <li>PEP Standards</li>
              </ul>
            </Panel>
          </Collapse>
        </div>
      </section>
      <LearnFromUs />
    </div>
  );
};

export default Python;
