import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import DataExtBefore from "../../../assets/images/docext.jpg";
import DataExtAfter from "../../../assets/images/docext-run.gif";

const DataExtraction = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Transcribe this document."</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createDataExtractionTask({
`}
            <code>{`callback_url:`}</code>
            {`  
  'http://www.example.com/callback',
`}
            <code>{`instruction:`}</code>
            {`
  'Transcribe this document',
`}
            <code>{`params`}</code>:
            <code>{`
attachments`}</code>
            : [{`{`}
            <code>{`
        type`}</code>
            :{` 'image',`}
            <code>{`
        content`}</code>
            :{" "}
            {`'invoice.jpg'
      }],`}
            <code>{`
labels`}</code>
            :{" "}
            {`['LineItem', ...],
`}
            <code>{`boundingboxes`}</code>:
            {` true,
}, (err, task) => {
    // do something with task
});
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={DataExtAfter} alt="" />
          ) : (
            <img src={DataExtBefore} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default DataExtraction;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
      "fields": [
        {
          "name": "Bll To",
          "value": "John Smith"
        },
        {
          "name": "Invoice Number",
          "value": "US-0001"
        },
        ...
      ]
    },
    "task_id": "337dfhn4eyw4cfne4y",
    "task": { ... }
}
            
`}
        </pre>
      </div>
    </div>
  );
};
