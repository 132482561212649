import React from "react";
import { Tabs } from "antd";
import DataExtraction from "../Produts/DocumentCodes/DataExtraction";

const { TabPane } = Tabs;


const Document = () => {
  return (
    <div className="row products-row">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Data Extraction" key="1">
          <DataExtraction />
        </TabPane>
      </Tabs>
    </div>
  );
};


export default Document;