import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import ClassificationBefore from "../../../assets/images/img-classification.jpg";
import ClassificationAfter from "../../../assets/images/img-classification.gif";

const Classification = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Select the most appropriate categories for the image shown.</h6>
      <div className="row m-0">
        <div className="col-md-6 code-box">
          <pre>
            {`client.createCategorizationTask({
`}
            <code>{`attachment:`}</code>
            {`  
  'http://example.com/img.jpg',
`}
            <code>{`instruction:`}</code>
            {`
  'Select the most appropriate categories
   for the image shown',
`}
            <code>{`taxonomies:`}</code>
            {`  {`}
            <code>{`
      image:`}</code>
            {`  {`}
            <code>{`
        type`}</code>
            :{`  category,`}
            <code>{`
        choices`}</code>
            :
            {`  ['Orange', 'Apple', 'Tree', 'Road'],
    }
},(err, task) => {
  // do something with task
});`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={ClassificationAfter} alt="" />
          ) : (
            <img src={ClassificationBefore} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Classification;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "response": {
    "taxonomies": {
      "image": ["Apple"],
    },
  },
}             
`}
        </pre>
      </div>
    </div>
  );
};
