import React, { useEffect } from "react";
import "./Training.css";
import practiceIcon from "../../assets/images/practice-icon.jpg";
import qaIcon from "../../assets/images/qa-icon.jpg";
import certificateIcon from "../../assets/images/certificates-icon.jpg";

const LearnFromUs = () => {
  return (
    <div className="container-fluid learn-from-us">
      <h1>Why Learn From Us</h1>
      <div className="row container m-auto">
        <div className="col-md-4 learn-block">
          <img src={practiceIcon} alt="" />
          <h3>Practice</h3>
          <p>
            Real-life project and data. Solve them on your own computer as you
            would in the office.
          </p>
        </div>
        <div className="col-md-4 learn-block">
          <img src={qaIcon} alt="" />
          <h3>Q&A Hub</h3>
          <p>
            Our expert instructors are happy to help. Post a question and get a
            personal answer by one of our instructors.
          </p>
        </div>
        <div className="col-md-4 learn-block">
          <img src={certificateIcon} alt="" />
          <h3>Ceritficates</h3>
          <p>
            Earn a verifiable certificate after each completed course. Celebrate
            your successes and share your progress with your professional
            network!
          </p>
        </div>
      </div>
    </div>
  );
};

export default LearnFromUs;
