import React from "react";
import { Tabs } from "antd";
import BoundingBox from "../Produts/VideoCodes/BoundingBox";
import Classification from "../Produts/VideoCodes/Classification";
import Cuboid from "../Produts/VideoCodes/Cuboid";
import Polygon from "../Produts/VideoCodes/Polygon";


const { TabPane } = Tabs;

const Video = () => {
  return (
    <div className="row products-row">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Bounding Box" key="1">
          <BoundingBox />
        </TabPane>
        <TabPane tab="Classification" key="2">
          <Classification />
        </TabPane>
        <TabPane tab="Cuboid" key="3">
          <Cuboid />
        </TabPane>
        <TabPane tab="Polygon" key="4">
          <Polygon />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Video;
