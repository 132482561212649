import React, { useEffect } from "react";
import "./Training.css";
import { Tag, Collapse } from "antd";
import {
  ClockCircleOutlined,
  FileDoneOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TrainingForm from "./TrainingForm";
import LearnFromUs from "./LearnFromUs";

import ReactGA from "react-ga";

const { Panel } = Collapse;

const tagStyle = {
  color: "black",
  padding: "6px 8px",
  borderRadius: "6px",
  fontSize: "14px",
  margin: "6px",
};

const MachineLearning = () => {


  useEffect(() => {
    document.title = "Training";

    ReactGA.pageview(window.location);
  }, []);

  return (
    <div className="page-home">
      <section
        className="training-banner container-fluid"
        id="machine-learning-banner"
      >
        <div className="container-xl row m-auto">
          <div className="col-md-6 training-info">
            <h1>Course 3 : Machine Learning</h1>
            <p>
              Machine Learning in Python builds upon the statistical knowledge
              you have gained earlier in the program. This course focuses on
              predictive modelling and enters multidimensional spaces which
              require an understanding of mathematical methods, transformations,
              and distributions. The course introduces these concepts as well as
              complex means of analysis such as clustering, factoring, Bayesian
              inference, and decision theory while also allowing you to exercise
              your Python programming skills.
            </p>
            <div className="training-tags">
              <Tag
                style={tagStyle}
                icon={
                  <ClockCircleOutlined
                    style={{ color: "#f8b63a", fontSize: "22px" }}
                  />
                }
              >
                30 Hours
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <ProjectOutlined
                    style={{ color: "green", fontSize: "22px" }}
                  />
                }
              >
                30 Exercises
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <FileDoneOutlined
                    style={{ color: "blue", fontSize: "22px" }}
                  />
                }
              >
                4 Assignments
              </Tag>
            </div>
          </div>
          <div className="training-form-div col-md-6">
            <TrainingForm />
          </div>
        </div>
      </section>
      <section className="course-content container-fluid">
        <div className="container-xl m-auto">
          <h5>Course Curriculum</h5>
          <Collapse
            style={{ backgroundColor: "#f7f7f7" }}
            expandIcon={() => {
              return null;
            }}
          >
            <Panel header="Module 0 : System Setup for Dependencies" key="1">
              <ul>
                <li>
                  Installation of Anaconda
                  <ul>
                    <li>Installing Anaconda</li>
                    <li>Setting up environment variables</li>
                    <li>Installing packages using Conda</li>
                  </ul>
                </li>
                <li>
                  Jupyter notebooks
                  <ul>
                    <li>Introduction to Jupyter notebooks</li>
                    <li>Tips and Tricks for using Jupyter notebook</li>
                    <li>Importing and checking version of packages</li>
                  </ul>
                </li>
              </ul>
            </Panel>

            <Panel
              header="Module 1 : Introduction to Artificial Intelligence"
              key="2"
            >
              <ul>
                <li>
                  Fields in AI
                  <ul>
                    <li>Computer Vision</li>
                    <li>Natural Language Processing</li>
                    <li>Recommendation Systems</li>
                  </ul>
                </li>
                <li>What is AI, ML, DL</li>
                <li>Applications of AI</li>
              </ul>
            </Panel>

            <Panel header="Module 2 : Introduction to Machine Learning" key="3">
              <ul>
                <li>Applications of Machine Learning</li>
                <li>Supervised Learning</li>
                <li>Unsupervised Learning</li>
                <li>Reinforcement Learning</li>
              </ul>
            </Panel>

            <Panel header="Module 3 : Python Modules for AI" key="4">
              <ul>
                <li>
                  NumPy
                  <ul>
                    <li>Lists vs Arrays</li>
                    <li>Dot Product</li>
                    <li>Vectors and Matrices</li>
                    <li>Matrix Product</li>
                  </ul>
                </li>
                <li>
                  Matplotlib
                  <ul>
                    <li>Line Chart</li>
                    <li>Bar Plot</li>
                    <li>Pie Chart</li>
                    <li>Scatter Plot</li>
                    <li>Histogram</li>
                    <li>Plotting Images</li>
                  </ul>
                </li>
                <li>
                  Pandas
                  <ul>
                    <li>Data Frame</li>
                    <li>Series</li>
                    <li>Range Data</li>
                    <li>Inspecting Data</li>
                    <li>Slice Data</li>
                    <li>Drop a Column</li>
                    <li>Concatenation</li>
                  </ul>
                </li>
                <li>Sklearn Library</li>
              </ul>
            </Panel>

            <Panel header="Module 4 : Regression Algorithms" key="5">
              <ul>
                <li>Simple Linear Regression</li>
                <li>Multiple Linear Regression</li>
                <li>Polynomial Regression</li>
                <li>Support Vector Regression (SVR)</li>
                <li>Decision Tree</li>
                <li>Random Forest</li>
              </ul>
            </Panel>

            <Panel header="Module 5 : Classification Algorithms" key="6">
              <ul>
                <li>Logistic Regression</li>
                <li>K-Nearest Neighbours</li>
                <li>Support Vector Machine (SVM)</li>
                <li>Naïve Bayes</li>
                <li>Decision Tree Classification</li>
                <li>Random Forest Classification</li>
              </ul>
            </Panel>

            <Panel header="Module 6 : Clustering Algorithms" key="7">
              <ul>
                <li>K-Means Clustering</li>
                <li>Hierarchical Clustering</li>
              </ul>
            </Panel>

            <Panel header="Module 7 : Dimensionality Reduction" key="8">
              <ul>
                <li>Principal Component Analysis</li>
                <li>Linear Discriminant analysis</li>
              </ul>
            </Panel>

            <Panel header="Module 8 : Neural Networks" key="9">
              <ul>
                <li>Human Brain Analogy</li>
                <li>Layers in Neural Network</li>
                <li>Activation Functions</li>
                <li>Feed Forward NN</li>
                <li>Backpropagation</li>
                <li>Loss Functions</li>
                <li>Optimization Algorithms</li>
                <li>Overfitting & Underfitting</li>
                <li>Regularization</li>
                <li>Dropout</li>
                <li>Epoch, Batches & Iteration</li>
                <li>Measuring Performance & Confusion Matrix</li>
                <li>Keras Hands on</li>
              </ul>
            </Panel>
          </Collapse>
        </div>
      </section>
      <LearnFromUs />
    </div>
  );
};

export default MachineLearning;
