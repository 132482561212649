import React, { useState } from "react";
import "./Training.css";
import { Form, Input, Button, Checkbox, Select, message } from "antd";
import { BrowserRouter as Link } from "react-router-dom";
import emailjs from "emailjs-com";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 16,
  },
};

const TrainingForm = () => {
  const formRef = React.createRef();

  const [checked, setChecked] = useState(false);

  const onSubmit = (values) => {
    if (!checked) {
      message.error("Please and Agree to the terms and conditions.");
      return;
    }

    formRef.current.resetFields();

    emailjs
      .send(
        "service_rhjxl8x",
        "template_tqhlv8y",
        values,
        "user_cX2huY0dePul7Uvv4ScI8"
      )
      .then(
        (result) => {
          console.log(result.text);
          message.success(
            "Your message has been successfully sent. We will contact you very soon!"
          );
        },
        (error) => {
          console.log(error.text);
          message.error("Error in sending message, please try again");
        }
      );
  };

  return (
    <div className="training-form-box">
      <h4>Course Enquiry</h4>
      <Form
        className="contact-form"
        {...layout}
        onFinish={onSubmit}
        ref={formRef}
      >
        <Form.Item
          label=""
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your name !",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label=""
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email !",
            },
          ]}
        >
          <Input placeholder="Email ID" />
        </Form.Item>
        <Form.Item
          label=""
          name="phone"
          rules={[
            {
              required: true,
              message: "Not a valid phone number !",
            },
          ]}
        >
          <Input placeholder="Phone Number" />
        </Form.Item>
        <Form.Item
          label=""
          name="course"
          rules={[
            {
              required: false,
              message: "Please input your phone number !",
            },
          ]}
        >
          <Select style={{ width: "100%" }} placeholder="Select course">
            <Option value="select course" disabled>
              Select Course
            </Option>
            <Option value="python">Python</Option>
            <Option value="data science">Data Science</Option>
            <Option value="machine learning">Machine Learning</Option>
            <Option value="computer vison">Computer Vision</Option>
            <Option value="natural language processing">
              Natural Language Processing
            </Option>
            <Option value="robotics">Robotics</Option>
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout} name="checked" valuePropName="checked">
          <Checkbox
            style={{
              height: "16px",
              width: "16px",
              marginRight: "6px",
              marginBottom: "6px",
            }}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
          By sending this form I confirm that I have read and accept&nbsp;
          <Link to="/privacy-policy">Privacy Policy</Link>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            className="training-form-button"
            type="primary"
            htmlType="submit"
            style={{ marginTop: "26px" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TrainingForm;
