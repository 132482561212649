import React, { useEffect } from "react";
import "./Products.css";
import ScaleVideo from "../../assets/images/scale-video.jpg";
import { Tabs } from "antd";
import BoundingBox from "./VideoCodes/BoundingBox";
import Classification from "./VideoCodes/Classification";
import Cuboid from "./VideoCodes/Cuboid";
import Polygon from "./VideoCodes/Polygon";

import ReactGA from "react-ga";


const { TabPane } = Tabs;

const Video = () => {

  useEffect(() => {
    document.title = "Products";

    ReactGA.pageview(window.location);
  }, []);

  const topData = [
    {
      title: "Bounding Box",
      text:
        "The annotators draw a bounding box over an object and label them with an object class.",
    },
    {
      title: "Classification",
      text:
        "Applies a classification algorithm to identify shared characteristics of certain classes.",
    },
    {
      title: "Cuboid",
      text:
        "Cuboid annotation is drawing a cube over an object to get 3D perspectives on height, width and depth.",
    },
    {
      title: "Polygon",
      text:
        "With polygons, annotators draw lines by placing dots around the outer edge of the object they want to annotate.",
    },
  ];

  const topCards = topData.map((card) => {
    return (
      <div className="col-12 col-md-4 top-card-outer">
        <div className="top-card-inner">
          <h4>{card.title}</h4>
          <p>{card.text}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="video-banner"
        className="container-fluid products-banner"
      ></section>
      <section className="container-fluid products-content">
        <div className="container-xl">
          <h1>
            <img src={ScaleVideo} alt="" />
            <span style={{ fontWeight: "400" }}>Heu</span> Video
          </h1>
          <p>The comprehensive annotation platform for Video.</p>
          <div className="row products-row">{topCards}</div>
          <h1>
            <span style={{ fontWeight: "400" }}>How It</span> Works
          </h1>
          <p>
            Maximizing operational efficiency and reducing the cost of projects.
          </p>
          <div className="row products-row">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Bounding Box" key="1">
                <BoundingBox />
              </TabPane>
              <TabPane tab="Classification" key="2">
                <Classification />
              </TabPane>
              <TabPane tab="Cuboid" key="3">
                <Cuboid />
              </TabPane>
              <TabPane tab="Polygon" key="4">
                <Polygon />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Video;
