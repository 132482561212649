import React, { useEffect } from "react";
import "./Solutions.css";
import AOS from "aos";
import VehicleDetectionTop from "../../assets/images/solution_new/Vehicle-Detection.jpg";
import VehicleDetectionContent from "../../assets/images/solutions/vechicle-detect.jpg";
import HelmetDetectionTop from "../../assets/images/solution_new/Helmet-Detection.jpg";
import HelmetDetectionContent from "../../assets/images/solutions/helmet.jpg";
import NumberPlateTop from "../../assets/images/solution_new/Number-plate.jpg";
import NumberPlateContent from "../../assets/images/solutions/number-plate.jpg";
import VehicleAttribitesTop from "../../assets/images/solution_new/Vehicle-Attributes.jpg";
import VehicleAttribitesContent from "../../assets/images/solutions/vehicle-recog.jpg";
import DriverMonitoringTop from "../../assets/images/solution_new/Drivers-Monitoring.jpg";
import DriverMonitoringContent from "../../assets/images/solutions/driver-monitor.jpg";
import TrafficMonitoringTop from "../../assets/images/solution_new/Traffic-Monitoring.jpg";
import TrafficMonitoringContent from "../../assets/images/solutions/traffic.jpg";

import ReactGA from "react-ga";

const Healthcare = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 50,
    });

    document.title = "Solutions";

    ReactGA.pageview(window.location);
  }, []);

  const data = [
    {
      name: "Vehicle Detection",
      text:
        "The vehicle detection solution designed to give an early cautioning alarm to the driver when vehicles approach one another. Our system provides a low cost solution helping to reduce the risk of collisions between industrial vehicles. Our Vehicle Detection System is ideally suited for large scale off highway applications where vehicle movements and mixed vehicle types are operating together for example, open cast mines and quarries.",
      topImage: VehicleDetectionTop,
      contentImage: VehicleDetectionContent,
    },
    {
      name: "Helmet Detection",
      text:
        "No helmet detection system that helps increasing ridership safety. Road fatality rates are increasing day by day & one of the main reasons of road fatalities is not using helmets while riding. Active law enforcement may help increase compliance, but ubiquitous enforcement requires many officers and may cause traffic jams and safety issues.",
      topImage: HelmetDetectionTop,
      contentImage: HelmetDetectionContent,
    },
    {
      name: "Number Plate Recognition",
      text:
        "Number Plate Recognition is a highly accurate system capable of reading vehicle number plates without human intervention through the use of high speed image capture with supporting illumination, detection of characters within the images provided, verification of the character sequences as being those from a vehicle license plate, character recognition to convert image to text; so ending up with a set of metadata that identifies an image containing a vehicle license plate and the associated decoded text of that plate.",
      topImage: NumberPlateTop,
      contentImage: NumberPlateContent,
    },
    {
      name: "Vehicle Attributes",
      text:
        "Our vehicle attributes recognition solution lets you recognize Vehicle Colour, Make and Model. The solution can lessen need of a human security guard at an entrance of apartments industries.",
      topImage: VehicleAttribitesTop,
      contentImage: VehicleAttribitesContent,
    },
    {
      name: "Driver's Alertness Monitoring",
      text:
        "Drowsiness detection is a safety technology that can prevent accidents that are caused by drivers who fell asleep while driving. Driver drowsiness or awareness detection systems use cameras in the vehicle cockpit to determine if a driver’s attention is still on the road and on operating the vehicle safely. In the event that the system’s inputs no longer meet minimum accepted criteria, the system uses audio and visual alerts to regain the driver’s attention.",
      topImage: DriverMonitoringTop,
      contentImage: DriverMonitoringContent,
    },
    {
      name: "Traffic Monitoring",
      text:
        "Our Traffic monitoring solution identifies cars, accidents, gridlocks, and dangerous driving to make roads safer by sending alerts of crashes and illegal driving and to improve traffic management by counting and identifying vehicles and pedestrians.",
      topImage: TrafficMonitoringTop,
      contentImage: TrafficMonitoringContent,
    },
  ];

  const topCards = [];

  const contentCards = [];

  data.forEach((item) => {
    topCards.push(
      <div class="project col-md-4 col-12" data-aos="fade" style={{height : "290px"}}>
        <div class="project__card">
          <div class="project__image">
            <img src={item.topImage} alt="" />
          </div>
          <div class="project__detail">
            <h2 class="project__title">{item.name}</h2>
          </div>
        </div>
        <div class="inilab">{item.name}</div>
      </div>
    );

    contentCards.push(
      <div className="col-12 col-md-12 row content-card">
        <div className="col-12 col-md-7 content-card-info">
          <h4>{item.name}</h4>
          <p>{item.text}</p>
        </div>
        <div className="col-12 col-md-5 content-card-image">
          <img src={item.contentImage} alt={item.name} />
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="mobility-banner"
        className="container-fluid solutions-banner"
      ></section>
      <section className="container-fluid solutions-content">
        <div className="container-xl">
          <h1>
            <span style={{ fontWeight: "400" }}>Mobility</span> Use Cases
          </h1>
          <p>
            Powering self-driving models with highly accurate training and
            validation data.
          </p>
          <div className="row solutions-row">{topCards}</div>
          <div className="row solutions-row">{contentCards}</div>
        </div>
      </section>
    </div>
  );
};

export default Healthcare;
