import React, { useEffect } from "react";
import "./Solutions.css";
import AOS from "aos";
import FaceDetectionTop from "../../assets/images/solution_new/Face-Detection.jpg";
import FaceDetectionContent from "../../assets/images/solutions/facedetection.jpg";
import FaceRecognitionTop from "../../assets/images/solution_new/Face-Recognition.jpg";
import FaceRecognitionContent from "../../assets/images/solutions/facerecog.jpg";
import FaceLandmarks from "../../assets/images/solution_new/face-landmarks.jpg";
import EmotionRecognitionTop from "../../assets/images/solution_new/Emotion-Recognition.jpg";
import EmotionRecognitionContent from "../../assets/images/solutions/emotion.jpg";
import GazeRecognitionTop from "../../assets/images/solution_new/Gaze-Estimation.jpg";
import GazeRecognitionContent from "../../assets/images/solutions/eyegaze.jpg";
import Face3DTop from "../../assets/images/solution_new/3d-face.jpg";
import Face3DContent from "../../assets/images/solutions/face3d.jpg";

import ReactGA from "react-ga";

const Face = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 50,
    });

    document.title = "Solutions";

    ReactGA.pageview(window.location);
  }, []);

  const data = [
    {
      name: "Face Detection",
      text:
        "Face detection is used to identify the presence of people’s faces within digital images.",
      topImage: FaceDetectionTop,
      contentImage: FaceDetectionContent,
      applications: ["Camera Auto Focus", "Counting people"],
    },
    {
      name: "Face Recognition",
      text:
        "Face recognition is a method of identifying or verifying the identity of an individual using their face. Face recognition systems can be used to identify people in photos, video, or in real-time.",
      topImage: FaceRecognitionTop,
      contentImage: FaceRecognitionContent,
      applications: [
        "Attendance Management System",
        "Control Access to Sensitive Areas",
        "Aid Forensic Investigations",
      ],
    },
    {
      name: "Face Landmarks",
      text:
        "Facial landmarks are used for localizing and representing salient regions or facial parts of the person’s face.",
      topImage: FaceLandmarks,
      contentImage: FaceLandmarks,
      applications: [
        "Personalised 3d emojis",
        "Head pose estimation",
        "Face swapping",
        "Blink detection",
        "Drowsiness detection",
      ],
    },
    {
      name: "Emotion Detection",
      text:
        "Emotion detection is great for an ad campaign that wants to track attention, engagement, and sentiment from viewers.",
      topImage: EmotionRecognitionTop,
      contentImage: EmotionRecognitionContent,
      applications: ["Security surveillance", "Hiring", "Clinical diagnosis"],
    },
    {
      name: "Gaze Estimation",
      text:
        "Gaze estimation lets you estimate eye gaze direction in images, compute and return high-precision eye center positions and eye gaze direction vectors.",
      topImage: GazeRecognitionTop,
      contentImage: GazeRecognitionContent,
      applications: ["Online Education", "Ad precise delivery"],
    },
    {
      name: "3D Face Reconstruction",
      text:
        "3D face model can be reconstructed by using single RGB image, the face surface information can be clearly described, and the real 3D model can be quickly output.",
      topImage: Face3DTop,
      contentImage: Face3DContent,
      applications: ["Simulative reshaping", "Personalized game avatar"],
    },
  ];

  const topCards = [];

  const contentCards = [];

  data.forEach((item) => {
    topCards.push(
      <div class="project col-md-4 col-12" data-aos="fade" style={{height : "290px"}}>
        <div class="project__card">
          <div class="project__image">
            <img src={item.topImage} alt="" />
          </div>
          <div class="project__detail">
            <h2 class="project__title">{item.name}</h2>
          </div>
        </div>
        <div class="inilab">{item.name}</div>
      </div>
    );

    contentCards.push(
      <div className="col-12 col-md-12 row content-card">
        <div className="col-12 col-md-7 content-card-info">
          <h4>{item.name}</h4>
          <p>{item.text}</p>
          {item.applications ? (
            <div className="applications">
              <h6>Applications : </h6>
              <ul>
                {item.applications.map((i) => (
                  <li>{i}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="col-12 col-md-5 content-card-image">
          <img src={item.contentImage} alt={item.name} />
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="face-banner"
        className="container-fluid solutions-banner"
      ></section>
      <section className="container-fluid solutions-content">
        <div className="container-xl">
          <h1>
            <span style={{ fontWeight: "400" }}>Face</span> Use Cases
          </h1>
          <p>Powering computer vision for Face recognition applications.</p>
          <div className="row solutions-row">{topCards}</div>
          <div className="row solutions-row">{contentCards}</div>
        </div>
      </section>
    </div>
  );
};

export default Face;
