import React, { useEffect } from "react";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title="Heu - Terms of Use";
  }, []);

  return (
    <div className="page">
      <section id="privacy-policy" className="container-fluid">
        <div className="container-xl m-auto">
          <h1>
            <span style={{ fontWeight: "400" }}>Terms Of</span> Use
          </h1>
          <p>
            ​These terms and conditions of use ("Terms") apply to your use of
            the www.heu.ai website (the "Site"), provided by HEU Technologies
            and its affiliates ("HEU Technologies").
          </p>
          <p>
            HEU Technologies does not take any responsibility for the accuracy
            of the information present on this Site. HEU Technologies reserves
            the right to change any and/or all information (including Terms). If
            HEU Technologies does make any such changes, the revised documents
            will be posted on this Site and the publication date will be
            updated. However, HEU Technologies will not provide any other type
            of notice to you directly. We sincerely encourage you to
            periodically check all documents relevant to your situation in order
            to stay informed about their status. For more information about our
            online privacy and cookie practices please read our Privacy Policy.
          </p>
          <h6>WARRANTY DISCLAIMER</h6>
          <p>
            HEU TECHNOLOGIES PRIVATE LIMITED, a Technology company, together
            with its affiliates and subsidiaries (collectively “HEU
            Technologies” or “we”), has prepared this Privacy Policy (“Policy”)
            to outline its online data protection practices. This Policy
            explains why and what information about you will be collected by HEU
            Technologies when you visit its website at www.heu.ai, and when you
            contact HEU Technologies by sending an email, filing in the contact
            form or otherwise, or when you apply for a job posted on our
            Website.
          </p>
          <p>
            This policy is an integral part of the Terms of Use of this Website.
            We might update this Policy in the future, so please make sure to
            read it from time to time. In case of any questions or comments as
            for this Policy, please reach out to us at info@heu.ai.
          </p>
          <h6>LIABILITY ​</h6>
          <p>
            Under no circumstances shall HEU Technologies be liable for any kind
            of damages indirect or consequential, including loss of profits or
            revenues and/or costs of replacement goods or damages resulting from
            the use of the information or materials present, even if HEU
            Technologies was informed in advance of the possibility of such
            damages.
          </p>
          <h6>USER CONDUCT</h6>
          <p>While using the Site, you shall not:</p>
          <ul className="privacy-page-list">
            <li>
              Upload, post or transmit to, or otherwise publish through, the
              Site any material that violates any provision of these Terms.
            </li>
            <li>
              Restrict or inhibit any other user from using and enjoying the
              Site.
            </li>
            <li>
              Conduct yourself or encourage others to conduct themselves in a
              manner that would constitute a criminal offense or give rise to
              civil liability.
            </li>
            <li>
              Impersonate or misrepresent your affiliation with any other person
              or entity.
            </li>
            <li>
              Upload, post, publish, transmit, reproduce, distribute or in any
              way exploit any information or other material obtained through the
              Site for commercial purposes (other than as expressly permitted by
              the provider of such information or other material).
            </li>
            <li>
              Engage in spamming or flooding; or Attempt to gain unauthorized
              access to other computer systems through the Site.
            </li>
          </ul>
          <p>
            HEU Technologies retains the right, but does not assume any
            obligation, to monitor the Site and to disclose any information as
            HEU Technologies deems necessary or appropriate to satisfy any law,
            regulation or other governmental request, to comply with any legal
            notice or other legal process, to operate the Site properly, to
            protect ourselves or our users or for other reasons as HEU
            Technologies determines in our sole discretion. HEU Technologies
            reserves the right to remove or refuse to post any information or
            materials, in whole or in part, that we determine, are unacceptable,
            undesirable, inappropriate or in violation of these Terms. HEU
            Technologies may also suspend or terminate your use of the Site at
            any time.
          </p>
          <h6>Software Downloads</h6>
          <p>
            Any software that is made available to download from this Site is
            the copyrighted work of HEU Technologies and/or its suppliers. Such
            software shall be used in accordance with the respective terms of
            the End User License Agreement (EULA) that accompanies the software.
            Any use of the Software not in accordance with the respective EULA
            is expressly prohibited.
          </p>
          <h6>Content Use</h6>
          <p>
            All the information, communications, software, scripting, photos,
            text, video, graphics, music, sounds, images and other materials
            found on this Site (collectively referred to as "Content") may be
            used for informational and non-commercial or personal use only,
            provided you do not modify the materials and that you retain all
            copyright and other proprietary notices contained in the materials.
          </p>
          <p>
            You may not use the content of the Site for commercial purposes
            without the prior expressed written consent of HEU Technologies. The
            unauthorized copying, displaying, or other use of any Content from
            this Site is a violation of the domestic and/or international
            legislation and may be pursued to the full extent of law. Permission
            to link to HEU Technologies from any site is not required, but prior
            notification to HEU Technologies would be appreciated.
          </p>
          <h6>COPY RIGHT AND TRADEMARKS ​</h6>
          <p>
            All product and brand names are trademarks or registered trademarks
            of their respective holders. The trademark of HEU Technologies is
            provided for your information and by making it available HEU
            Technologies does not grant you a license to use it.
          </p>
          <p>
            The copyright in all material provided on this Site is held by HEU
            Technologies or by the original creator of the material. Neither
            title nor intellectual property rights are transferred to you but
            remain the full and complete rights and title of HEU Technologies or
            the original creator.
          </p>
          <h6>LINKS TO THIRD PARTY SITES ​</h6>
          <p>
            This Site may provide links to other third-party sites. HEU
            Technologies makes no representations whatsoever about any other
            site which you may access through this Site. You acknowledge and
            agree that HEU Technologies is not responsible for the content of
            any linked site or any link contained in a linked site. References
            on this Site to any names, marks, products or services of any third
            parties or hypertext links to third party sites or information are
            provided solely for your convenience and do not constitute an
            endorsement by or association with HEU Technologies of such sites or
            the content, products, advertising or other materials presented on
            such sites.
          </p>
          <p>
            You acknowledge and agree that HEU Technologies is not responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, goods or services available on such linked sites.
          </p>
          <h6>Questions</h6>
          <p>
            Please contact us at info@heu.ai if you have any questions about HEU
            Technologies privacy policy or other legal guidelines.
          </p>
        </div>
      </section>
    </div>
  );
};

export default TermsOfUse;
