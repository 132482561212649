import React from "react";
import { Tabs } from "antd";

import BoundingBox from "../Produts/ImageCodes/BoundingBox";
import Classification from "../Produts/ImageCodes/Classification";
import Cuboid from "../Produts/ImageCodes/Cuboid";
import LinesSplines from "../Produts/ImageCodes/LinesSplines";
import Point from "../Produts/ImageCodes/Point";
import Segmentation from "../Produts/ImageCodes/Segmentation";
import Transcription from "../Produts/ImageCodes/Transcription";

const { TabPane } = Tabs;

const Image = () => {
  return (
    <div className="row products-row">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Bounding Box" key="1">
          <BoundingBox />
        </TabPane>
        <TabPane tab="Classification" key="2">
          <Classification />
        </TabPane>
        <TabPane tab="Cuboid" key="3">
          <Cuboid />
        </TabPane>
        <TabPane tab="Lines and Splines" key="4">
          <LinesSplines />
        </TabPane>
        <TabPane tab="Point" key="5">
          <Point />
        </TabPane>
        <TabPane tab="Segmentation" key="6">
          <Segmentation />
        </TabPane>
        <TabPane tab="Transcription" key="7">
          <Transcription />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Image;
