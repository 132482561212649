import React, { useState } from "react";
import { Button, Popover } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import SegmentationBefore from "../../../assets/images/img-seg.jpg";
import SegmentationAfter from "../../../assets/images/img-seg-run.jpg";

const Segmentation = () => {
  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
  };

  const show = () => {
    setIsVisible(true);
  };

  return (
    <div className="product-sub-tab">
      <h6>"Segment by vehicles, bikes, roads and background in each image."</h6>
      <div className="row m-0">
        <div className="col-md-6 codes-box">
          <pre>
            {`client.createSegmentannotationTask({
`}
            <code>{`instruction:`}</code>
            {`
   'Segment by vehicles, bikes, 
   roads and background in each image.',
`}
<code>{`labels:`}</code>
            {`  ['vehicle', 'background', 'road']
},(err, task) => {
  // do something with task
});
`}
          </pre>
          <Popover
            content={<Content close={hide} />}
            title={null}
            trigger="click"
            visible={isVisible}
          >
            <Button className="code-run-button" onClick={show}>
              Run Code
            </Button>
          </Popover>
        </div>
        <div className="col-md-6 image-box">
          {isVisible ? (
            <img src={SegmentationAfter} alt="" />
          ) : (
            <img src={SegmentationBefore} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Segmentation;

const Content = ({ close }) => {
  return (
    <div className="code-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>JSON Response</h3>
        <CloseCircleOutlined
          className="close-button"
          onClick={() => {
            close();
          }}
        />
      </div>
      <hr />
      <div>
        <pre style={{ padding: "0px", boxShadow: "none" }}>
          {`{
  "response" : {
        "instances" : {},
        "labelMapping" : {
            "Background" : {
                "color" : "#eedc23",
                "index" : 3
            },
          
            "Vehicle" : {
                "color" : "#6200f6",
                "index" : 2
            },
        
            "Road" : {
                "color" : "#00f66e",
                "index" : 1
            }
        },
        "annotations" : {
            "combined" : {
                "indexedImage" : "https://example.com/image",
                "image" : "https://example.com/image"
            },
           
            "unlabeled" : null
        }
    },
    "task_id": "475573734ndhr2"
}                     
`}
        </pre>
      </div>
    </div>
  );
};
