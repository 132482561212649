import React, { useEffect } from "react";
import "./Training.css";
import { Tag, Collapse } from "antd";
import {
  ClockCircleOutlined,
  FileDoneOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TrainingForm from "./TrainingForm";
import LearnFromUs from "./LearnFromUs";

import ReactGA from "react-ga";

const { Panel } = Collapse;

const tagStyle = {
  color: "black",
  padding: "6px 8px",
  borderRadius: "6px",
  fontSize: "14px",
  margin: "6px",
};

const Robotics = () => {

  useEffect(() => {
    document.title = "Training";

    ReactGA.pageview(window.location);
  }, []);

  return (
    <div className="page-home">
      <section className="training-banner container-fluid" id="robotics-training-banner">
        <div className="container-xl row m-auto">
          <div className="col-md-6 training-info">
            <h1>Course 6 : Robotics</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries
            </p>
            <div className="training-tags">
              <Tag
                style={tagStyle}
                icon={
                  <ClockCircleOutlined
                    style={{ color: "#f8b63a", fontSize: "22px" }}
                  />
                }
              >
                20 Hours
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <ProjectOutlined
                    style={{ color: "green", fontSize: "22px" }}
                  />
                }
              >
                25 Exercises
              </Tag>
              <Tag
                style={tagStyle}
                icon={
                  <FileDoneOutlined
                    style={{ color: "blue", fontSize: "22px" }}
                  />
                }
              >
                4 Assignments
              </Tag>
            </div>
          </div>
          <div className="training-form-div col-md-6">
            <TrainingForm />
          </div>
        </div>
      </section>
      <section className="course-content container-fluid">
        <div className="container-xl m-auto">
          <h5>Course Curriculum</h5>
          <Collapse
            style={{ backgroundColor: "#f7f7f7" }}
            expandIcon={() => {
              return null;
            }}
          >
            <Panel header="Module 0 : Python Setup" key="1">
              <ul>
                <li>Installation of Python</li>
                <li>Command line Basics</li>
                <li>Modes of Executing Python code</li>
                <li>Running first Python Code</li>
              </ul>
            </Panel>

            <Panel
              header="Module 1 : Introduction to Robotics and types of Robots"
              key="2"
            >

            </Panel>

            <Panel header="Module 2 : Kinematics of Robots" key="3">

            </Panel>

            <Panel
              header="Module 3 : Dynamics of Robots"
              key="4"
            >

            </Panel>

            <Panel header="Module 4 : ROS Essentials" key="5">

            </Panel>

            <Panel header="Module 5 : Build your own Robot Environment" key="6">

            </Panel>

            <Panel header="Module 6 : Atonomous Navigation" key="6">

            </Panel>

            <Panel header="Module 7 : Robot Vision" key="6">

            </Panel>
          </Collapse>
        </div>
      </section>
      <LearnFromUs />
    </div>
  );
};

export default Robotics;
