import React, { useEffect } from "react";
import "./Solutions.css";
import AOS from "aos";
import DroneAgriContent from "../../assets/images/solutions/drone-agri.jpg";
import DroneAgriTop from "../../assets/images/solution_new/Precision-Agriculture.jpg";
import InfraMaintenanceTop from "../../assets/images/solution_new/Infrastructure-Maintenance.jpg";
import InfraMaintenanceContent from "../../assets/images/solutions/drone-solarpanel.jpg";
import DroneSecurity from "../../assets/images/solutions/drone-border.jpg";

import ReactGA from "react-ga";

const Drone = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 50,
    });

    document.title = "Solutions";

    ReactGA.pageview(window.location);
  }, []);

  const data = [
    {
      name: "Precision Agriculture",
      text:
        "Precision Agriculture is a farming management concept that employs drones to meausre, assess and respond to variations found in crops. it allows farmers to better understand the health and vitality of their crops. By using our platform along with a multispectral or thermal camera.",
      topImage: DroneAgriTop,
      contentImage: DroneAgriContent,
    },
    {
      name: "Infrastructure Maintenance",
      text:
        "Large, complex structures are best surveyed from the air. It’s no wonder that inspection has been one of the first UAV applications to be quickly and widely embraced world-wide.",
      topImage: InfraMaintenanceTop,
      contentImage: InfraMaintenanceContent,
      applications: [
        "Oil and Gas pipeline inspections",
        "Solar panel inspections",
        "Power line / cable inspections",
        "Cooling tower inspections",
        "Wind turbine / windmill inspections",
        "Railway / train line inspections",
        "Bridge inspections",
      ],
    },
    {
      name: "Security & Surveillance",
      text:
        "Having an electronic eye-in-the-sky is as close as it gets to a silver bullet for detection, recognition, and deterrence in the security context. The ability to schedule, repeat and monitor autonomous missions, so that drone fleets can augment human security guards and fixed cameras, is a powerful force multiplier.",
      topImage: DroneSecurity,
      contentImage: DroneSecurity,
      applications: [
        "Anti-terror operations",
        "Criminal investigations",
        "Traffic surveillance",
        "Searching for missing persons",
        "Emergency communication networks",
        "Border surveillance",
        "Coastal surveillance",
        "Anti-piracy operations",
        "Controlling hostile demonstrations and rioting",
        "Monitoring international summit meetings",
        "Perimeter surveillance & monitoring",
      ],
    },
  ];

  const topCards = [];

  const contentCards = [];

  data.forEach((item) => {
    topCards.push(
      <div class="project col-md-4 col-12" data-aos="fade" style={{height : "290px"}}>
        <div class="project__card">
          <div class="project__image">
            <img src={item.topImage} alt="" />
          </div>
          <div class="project__detail">
            <h2 class="project__title">{item.name}</h2>
          </div>
        </div>
        <div class="inilab">{item.name}</div>
      </div>
    );

    contentCards.push(
      <div className="col-12 col-md-12 row content-card">
        <div className="col-12 col-md-7 content-card-info">
          <h4>{item.name}</h4>
          <p>{item.text}</p>
          {item.applications ? (
            <div className="applications">
              <h6>Applications : </h6>
              <ul>
                {item.applications.map((i) => (
                  <li>{i}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="col-12 col-md-5 content-card-image">
          <img src={item.contentImage} alt={item.name} />
        </div>
      </div>
    );
  });

  return (
    <div className="page">
      <section
        id="drone-banner"
        className="container-fluid solutions-banner"
      ></section>
      <section className="container-fluid solutions-content">
        <div className="container-xl">
          <h1>
            <span style={{ fontWeight: "400" }}>Drone</span> Use Cases
          </h1>
          <p>
            Powering computer vision for drones and mapping with highly accurate
            training data.
          </p>
          <div className="row solutions-row">{topCards}</div>
          <div className="row solutions-row">{contentCards}</div>
        </div>
      </section>
    </div>
  );
};

export default Drone;
